import { User } from '../../../@types/account'

export const unAuthUser: User = {
    id: '',
    firstName: '',
    email: '',
    lastName: '',
    userRole: 'Unauthorized',
    isEmailConfirm: false,
    autoLogoutDuration: 60,
    hasZohoAccess: false,
}
