import { Theme } from '@mui/material/styles'
import { responsiveFontSizes } from 'utils/formatFontSize'

// ----------------------------------------------------------------------

export default function Autocomplete(theme: Theme) {
    return {
        MuiAutocomplete: {
            styleOverrides: {
                paper: {
                    boxShadow: theme.customShadows.z20,
                },
                root: {
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '3px',
                    label: {
                        '@media (max-width:1400px)': {
                            maxWidth: 'calc(79% - 4px) !important',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            left: '-3px',
                        },
                    },
                },
                listbox: {
                    ...responsiveFontSizes({ sm: 9, md: 9, lg: 12 }),
                },
                label: {
                    // ...theme.typography.subtitle2,
                },
                input: {
                    padding: '0 0 2px 3px !important',
                    minHeight: '33px',
                    height: '33px',
                },
                inputRoot: {
                    minHeight: '33px',
                    padding: '0 0 2px 3px !important',
                },
                tag: {
                    maxWidth: 'calc(79% - 4px)',
                },
                endAdornment: {
                    display: 'table-cell',
                    verticalAlign: 'middle',
                    '@media (max-width:1400px)': {
                        top: 'calc(50% - 10px)',
                    },
                    /*
                    '@media (max-width:1200px)': {
                        top: 'calc(28%)'
                    },*/
                },
            },
        },
    }
}
