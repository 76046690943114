import React, { useEffect } from 'react'
// redux
import { getInitialize, startLoading, stopLoading } from 'store/slices/authJwt/authJwt'
import { getJwtToken, isValidToken } from 'store/slices/authJwt/helpers'
import { unAuthUser } from 'store/slices/authJwt/const'
import { isEmpty } from 'lodash'
import useAuth, { loadAppForUser, updateUserSession } from 'hooks/useAuth'
import { useAppDispatch, useAppSelector } from 'hooks/useAppRedux'
import SkeletonAuthenticatedApp from 'components/SkeletonLoaders/SkeletonAuthenticatedApp'

// ----------------------------------------------------------------------

type JwtProviderProps = {
    children: React.ReactNode
}

export default function JwtProvider({ children }: JwtProviderProps) {
    const dispatch = useAppDispatch()

    const { logout } = useAuth()

    const { user, isAuthenticated, isLoading } = useAppSelector((state) => state.authJwt)

    useEffect(() => {
        if (!isAuthenticated) {
            return
        }

        const ptr = setInterval(() => {
            if (isEmpty(user?.id)) {
                try {
                    clearInterval(ptr)
                } catch (e) {
                    console.log(e)
                }
                return
            }
            updateUserSession(user, logout)
        }, 10 * 60 * 1000)
        return () => clearInterval(ptr)
        // eslint-disable-next-line
    }, [isAuthenticated])

    useEffect(() => {
        dispatch(async () => {
            dispatch(startLoading())
            try {
                const accessToken = getJwtToken()
                if (accessToken && isValidToken(accessToken)) {
                    await dispatch(loadAppForUser(accessToken))
                } else {
                    dispatch(
                        getInitialize({
                            isAuthenticated: false,
                            user: unAuthUser,
                        })
                    )
                }
            } catch (error) {
                console.error(error)
                dispatch(
                    getInitialize({
                        isAuthenticated: false,
                        user: unAuthUser,
                    })
                )
            } finally {
                dispatch(stopLoading())
            }
        })
    }, [dispatch])

    if (isLoading) {
        return <SkeletonAuthenticatedApp />
    }

    return <>{children}</>
}
