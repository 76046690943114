import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
// material
import { alpha, styled } from '@mui/material/styles'
import { isEmpty } from 'lodash'
import { Avatar, Box, CardActionArea, Drawer, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
// hooks
import useAuth from 'hooks/useAuth'
import useCollapseDrawer from 'hooks/useCollapseDrawer'
// routes
// components
import Logo from 'components/Logo'
import Scrollbar from 'components/Scrollbar'
import NavSection from 'components/NavSection'
import { MHidden } from 'components/@material-extend'
import useScrollSaver from 'hooks/useScrollSaver'
//
import sidebarConfig from './SidebarConfig'
import { useAppSelector } from 'hooks/useAppRedux'
import { getCompanySettings } from '../../store/slices/company'
// ----------------------------------------------------------------------

export const DRAWER_WIDTH = 255
export const COLLAPSE_WIDTH = 102

const RootStyle = styled('div')(({ theme }) => ({
    backgroundColor: '#E5E8EB',
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.complex,
        }),
    },
}))

// ----------------------------------------------------------------------

type IconCollapseProps = {
    onToggleCollapse: VoidFunction
    collapseClick: boolean
}

function IconCollapse({ onToggleCollapse, collapseClick }: IconCollapseProps) {
    return (
        <Tooltip title='Mini Menu'>
            <CardActionArea
                onClick={onToggleCollapse}
                sx={{
                    width: 18,
                    height: 18,
                    display: 'flex',
                    cursor: 'pointer',
                    borderRadius: '50%',
                    alignItems: 'center',
                    color: 'text.primary',
                    justifyContent: 'center',
                    ...(collapseClick && {
                        borderWidth: 2,
                    }),
                }}
            >
                {!collapseClick && <KeyboardArrowLeftIcon sx={{ color: 'white' }} />}
                {collapseClick && <KeyboardArrowRightIcon sx={{ color: 'white' }} />}
            </CardActionArea>
        </Tooltip>
    )
}

type DashboardSidebarProps = {
    isOpenSidebar: boolean
    onCloseSidebar: VoidFunction
    isMobile: boolean
}

const DashboardSidebar = ({ isOpenSidebar, onCloseSidebar }: DashboardSidebarProps) => {
    const { pathname } = useLocation()
    const { user } = useAuth()
    const { LogoData, Name } = useAppSelector(getCompanySettings)

    const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } = useCollapseDrawer()
    const hiddenUp = useMediaQuery((theme: any) => theme.breakpoints.up('md')) as boolean

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar()
        }
    }, [pathname])

    // saving scroll
    const ref = useScrollSaver(isCollapse ?? false)

    const renderContent = (
        <Scrollbar
            scrollableNodeProps={{ ref }}
            sx={{
                height: 1,
                '& .simplebar-content': {
                    height: 1,
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            <Stack
                spacing={3}
                sx={{
                    px: 2.5,
                    pt: 3,
                    pb: 2,
                    ...(isCollapse && {
                        alignItems: 'center',
                    }),
                }}
            >
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                >
                    <Box sx={{ display: 'inline-flex', height: '42px' }}>
                        {isEmpty(LogoData) ? (
                            <Logo src={'/static/logo_inverse.png'} />
                        ) : (
                            <Avatar
                                alt='Remy Sharp'
                                src={'data:image/gif;base64,' + LogoData}
                                sx={{ mt: '2px' }}
                            />
                        )}
                        {!isCollapse && (
                            <Box sx={{ ml: 2 }}>
                                <Typography
                                    whiteSpace='nowrap'
                                    fontWeight='bold'
                                    variant='subtitle2'
                                    sx={{ color: '#FFFFFF' }}
                                >
                                    {/* {isEmpty(Name) ? '' : Name} */}
                                    {isEmpty(Name) ? '' : Name.split(' ').slice(0, 2).join(' ')}
                                    {!isEmpty(Name) && Name.split(' ').length > 2 && (
                                        <Tooltip
                                            title={Name}
                                            arrow
                                        >
                                            <span>...</span>
                                        </Tooltip>
                                    )}
                                </Typography>
                                <Typography
                                    whiteSpace='nowrap'
                                    variant='body2'
                                    sx={{ color: '#C4CDD5' }}
                                >
                                    {user?.userRole}
                                </Typography>
                            </Box>
                        )}
                    </Box>

                    <MHidden width='mdDown'>
                        {!isCollapse && (
                            <IconCollapse
                                onToggleCollapse={() => {
                                    onToggleCollapse()
                                    try {
                                        setTimeout(() => {
                                            window.dispatchEvent(new Event('resize'))
                                        }, 300)
                                    } catch (e) {
                                        console.log(e)
                                    }
                                }}
                                collapseClick={collapseClick}
                            />
                        )}
                    </MHidden>
                </Stack>
            </Stack>

            <NavSection
                navConfig={sidebarConfig}
                isShow={!isCollapse}
            />
        </Scrollbar>
    )

    return (
        <>
            <RootStyle
                sx={{
                    ///zIndex: 999999,
                    width: hiddenUp ? (collapseClick ? COLLAPSE_WIDTH : DRAWER_WIDTH) : 0,
                }}
            >
                {/* mobile */}
                <MHidden width='lgUp'>
                    <Drawer
                        open={isOpenSidebar}
                        onClose={onCloseSidebar}
                        PaperProps={{
                            sx: {
                                width: DRAWER_WIDTH,
                                bgcolor: '#292929', // #292929
                            },
                        }}
                    >
                        {renderContent}
                    </Drawer>
                </MHidden>
                {/* desktop */}
                <MHidden width='mdDown'>
                    <Drawer
                        open
                        variant='persistent'
                        onMouseEnter={onHoverEnter}
                        onMouseLeave={onHoverLeave}
                        PaperProps={{
                            sx: {
                                width: DRAWER_WIDTH,
                                borderRight: 0,
                                bgcolor: '#292929', // #292929
                                ...(isCollapse && {
                                    width: COLLAPSE_WIDTH,
                                }),
                                ...(collapseHover && {
                                    backdropFilter: 'blur(6px)',
                                    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                                    boxShadow: (theme) => theme.shadows[10],
                                    bgcolor: () => alpha('#292929', 0.88),
                                }),
                            },
                        }}
                    >
                        {renderContent}
                    </Drawer>
                </MHidden>
            </RootStyle>
        </>
    )
}

export default DashboardSidebar
