import { LogLevel } from '@azure/msal-browser'
import { AzureCloudInstance } from '@azure/msal-common/dist/authority/AuthorityOptions'

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const msalConfig = {
    auth: {
        clientId: '7e595279-2d9f-45e9-b7bb-d141bf1925ea',
        authority: 'https://login.microsoftonline.com/aa827ac4-4665-4dbb-98fa-fa4931a84709',
        redirectUri: window.location.origin + '/auth/login',
        azureCloudOptions: {
            azureCloudInstance: AzureCloudInstance.AzurePublic,
            tenant: 'aa827ac4-4665-4dbb-98fa-fa4931a84709',
        },
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            // @ts-expect-error: NEED better Typescript descr
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message)
                        return
                    case LogLevel.Info:
                        console.info(message)
                        return
                    case LogLevel.Verbose:
                        console.debug(message)
                        return
                    case LogLevel.Warning:
                        console.warn(message)
                        return
                }
            },
        },
    },
}
