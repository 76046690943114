import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Avatar(theme: Theme) {
    return {
        MuiAvatar: {
            styleOverrides: {
                colorDefault: {
                    color: theme.palette.text.secondary,
                    backgroundColor: theme.palette.grey[400],
                },
                root: {
                    '@media (max-width:1300px)': {
                        fontSize: 12,
                        width: 30,
                        height: 30,
                    },
                },
            },
        },
        MuiAvatarGroup: {
            styleOverrides: {
                avatar: {
                    fontSize: 16,
                    fontWeight: theme.typography.fontWeightMedium,
                    '&:first-of-type': {
                        fontSize: 14,
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.primary.lighter,
                    },
                },
            },
        },
    }
}
