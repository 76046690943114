import jwtDecode from 'jwt-decode'
import axios from 'axios'
import axiosInstance, { sandBoxInstance } from 'utils/axios'
import { User } from '../../../@types/account'
import { reportError } from 'utils/errorReport'
import { isEmpty } from 'lodash'

import { AES, enc } from 'crypto-js'

// ----------------------------------------------------------------------

export const decodeToken = (accessToken: string) => jwtDecode<Record<string, any>>(accessToken)

export const isValidToken = (accessToken: string) => {
    if (!accessToken) {
        return false
    }

    const decoded = jwtDecode<{ exp: number }>(accessToken)
    const currentTime = Date.now() / 1000

    return decoded.exp > currentTime
}

const secret = 'M$vSqaN&QxX4+tc-!p3UL*zDG9xL^Rw#dy!P$!!?m@c_xY_JbeUV5kk$=NyDNTq6rSA5d_hGrcVJn%?JFYj!RE$@BS9$Le**QVBgEGdXBATAp78Z9v+x9Pt@a?G'

export const getUserInfoJWT = (): Partial<User> => {
    try {
        const data = decodeToken(getJwtToken())

        let autoLogoutDuration = 60
        try {
            autoLogoutDuration = parseInt(data?.autoLogoutDuration || '60')
        } catch (_) {
            // do nothing
        }

        return {
            id: data['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'] || '',
            email: data['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'] || '',
            userRole: data['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] || '',
            companyHierarchyId: data.CompanyHierarchyId || '',
            companyId: data.CompanyId || '',
            contactName: data.ContactName || '',
            hasActiveSubscription: (data.HasActiveSubscription || '').toLowerCase() === 'true',
            autoLogoutDuration,
            isCompanyPermanent: (data.IsCompanyPermanent || '').toLowerCase() === 'true',
            isFirstVisit: (data.IsFirstVisit || '').toLowerCase() === 'true',
            hasZohoAccess: (data?.HasZohoAccess || '').toLowerCase() === 'true',
        }
    } catch (e) {
        reportError(e)
        /// pass
        return {} as Partial<User>
    }
}

export const getJwtToken = () => {
    try {
        return AES.decrypt(localStorage.getItem('accessToken') || '', secret).toString(enc.Utf8)
    } catch (e) {
        reportError(e)
        return ''
    }
}

export const sessionExists = () => !isEmpty(localStorage.getItem('accessToken'))

export const setSession = (accessToken: string | null) => {
    if (accessToken) {
        // localStorage.removeItem('accessToken');
        // Encrypt
        localStorage.setItem('accessToken', AES.encrypt(accessToken, secret).toString())
        sandBoxInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
        delete axiosInstance.defaults.headers.common.Authorization
        delete sandBoxInstance.defaults.headers.common.Authorization
    }
}
