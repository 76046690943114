import React from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Theme } from '@mui/material/styles'
import { Box } from '@mui/material'
import { IconPlus } from 'assets/IconPlus'
import { IconMinus } from 'assets/IconMinus'

// ----------------------------------------------------------------------

const ICON_SIZE = { width: 20, height: 20 }

export default function TreeView(theme: Theme) {
    return {
        MuiTreeView: {
            defaultProps: {
                defaultCollapseIcon: <IconMinus {...ICON_SIZE} />,
                defaultExpandIcon: <IconPlus {...ICON_SIZE} />,
                defaultEndIcon: (
                    <Box
                        component={CloseRoundedIcon}
                        {...ICON_SIZE}
                        sx={{ color: 'text.secondary' }}
                    />
                ),
            },
        },
        MuiTreeItem: {
            styleOverrides: {
                label: { ...theme.typography.body2 },
                iconContainer: { width: 'auto' },
            },
        },
    }
}
