import { Stack } from '@mui/system'
import React from 'react'
import SkeletonCardsLoading from './SkeletonCardsLoading'
import { Divider, Skeleton } from '@mui/material'
import SkeletonTableLoading from 'components/SkeletonLoaders/SkeletonTableLoading'

const SkeletonDashboard = () => (
    <Stack>
        <Skeleton
            animation='wave'
            variant='text'
            sx={{ fontSize: '3rem' }}
        />
        <SkeletonCardsLoading />
        <Divider />
        <br />
        <SkeletonTableLoading />
    </Stack>
)

export default SkeletonDashboard
