import { tau } from './math'
import { rotatePoint } from './rotatePoint'
import * as d3_7 from 'd3-7'

function sideLength(area: number) {
    const num = 2 * area
    const denom = 3 * Math.sqrt(3)
    return Math.sqrt(num / denom)
}

function drawBuild(theta?: number): d3_7.SymbolType {
    const t = theta || 0

    return {
        draw(context, size) {
            const s = sideLength(size)
            const R = s
            // @ts-expect-error: NEED better Typescript descr
            // eslint-disable-next-line prefer-spread
            context.moveTo.apply(context, rotatePoint(R, 0, t))
            for (let i = 0; i < 6; ++i) {
                const a = (tau * i) / 6
                const x = Math.cos(a) * R
                const y = Math.sin(a) * R
                // @ts-expect-error: NEED better Typescript descr
                // eslint-disable-next-line prefer-spread
                context.lineTo.apply(context, rotatePoint(x, y, t))
            }
            context.closePath()
        },
    }
}

export const hexagon = {
    draw: drawBuild(tau / 16).draw, // Rotate 1/12 turn back so the shape is oriented with a point upward.
}
