import React from 'react'
import { styled } from '@mui/material/styles'
import { TabList, TabListProps } from '@mui/lab'
import MTab from './MTab'

const StyledTabList = styled(TabList)(({ theme }) => ({
    '&:not(:last-child)': {
        marginRight: theme.spacing(0),
    },
    borderRadius: '5px 5px',
    backgroundColor: '#fff',
    marginBottom: theme.spacing(1),
}))

interface MTabListProps extends TabListProps {
    tabs: {
        label: string | React.ReactNode
        value: string
        disabled?: boolean | undefined
        component?: () => Element | React.ReactNode
        subHeader?: React.ReactNode | string
    }[]
}

/*
label: ReactNode;
value: string;
disabled?: boolean | undefined;
component?: ReactNode;
subHeader?: ReactNode;
 */

const MTabList = ({ tabs, children, ...rest }: MTabListProps) => (
    <StyledTabList
        {...rest}
        indicatorColor='primary'
        TabIndicatorProps={{
            style: {
                backgroundColor: 'transparent',
            },
        }}
        variant='fullWidth'
    >
        {tabs &&
            tabs.map((tab: any) => (
                <MTab
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                />
            ))}
        {children}
    </StyledTabList>
)

export default MTabList
