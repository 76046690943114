import { Theme } from '@mui/material/styles'
import { responsiveFontSizes } from 'utils/formatFontSize'

// ----------------------------------------------------------------------
// eslint-disable-next-line
export default function SvgIcon(theme: Theme) {
    return {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: '1.3rem',
                    ...responsiveFontSizes({ sm: 12, md: 12, lg: 18 }),
                },
                fontSizeSmall: {
                    ...responsiveFontSizes({ sm: 12, md: 12, lg: 18 }),
                },
                fontSizeLarge: {
                    width: 32,
                    height: 32,
                    fontSize: 'inherit',
                },
            },
        },
    }
}
