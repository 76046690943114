import { PATH_SUPERADMIN } from './paths'
// layouts
import SuperAdminGuard from 'guards/SuperAdminGuard'
import { RouteObject } from 'react-router-dom'
import DashboardLayout from 'layouts/dashboard'
import { lazyLoad } from '../utils/routes'

// ----------------------------------------------------------------------

const SuperAdminDashBoard = lazyLoad(() => import('views/superAdmin/Dashboard'), 'SuperAdminDashBoard')
const SuperAdminCompaniesPage = lazyLoad(() => import('views/superAdmin/Companies'), 'SuperAdminCompaniesPage')
const SuperAdminUsersPage = lazyLoad(() => import('views/superAdmin/Users'), 'SuperAdminUsersPage')
const SuperAdminEditCompanyPage = lazyLoad(() => import('views/superAdmin/Companies/EditCompany'), 'SuperAdminEditCompanyPage')
const SuperAdminNotificationsPage = lazyLoad(() => import('views/superAdmin/Notifications'), 'SuperAdminNotificationsPage')
const SuperAdminBuglistPage = lazyLoad(() => import('views/superAdmin/BugList'), 'SuperAdminBuglistPage')

const SuperUserRoutes = [
    {
        path: PATH_SUPERADMIN.manage.dashboard,
        element: (
            <SuperAdminGuard>
                <DashboardLayout title={'Manage / Dashboard'}>
                    <SuperAdminDashBoard />
                </DashboardLayout>
            </SuperAdminGuard>
        ),
    },
    {
        path: PATH_SUPERADMIN.manage.companies,
        element: (
            <SuperAdminGuard>
                <DashboardLayout title={'Manage / Companies'}>
                    <SuperAdminCompaniesPage />
                </DashboardLayout>
            </SuperAdminGuard>
        ),
    },
    {
        path: PATH_SUPERADMIN.manage.users,
        element: (
            <SuperAdminGuard>
                <DashboardLayout title={'Manage / Users'}>
                    <SuperAdminUsersPage />
                </DashboardLayout>
            </SuperAdminGuard>
        ),
    },
    {
        path: PATH_SUPERADMIN.manage.editCompanies,
        element: (
            <SuperAdminGuard>
                <DashboardLayout title={'Manage / Edit Company'}>
                    <SuperAdminEditCompanyPage />
                </DashboardLayout>
            </SuperAdminGuard>
        ),
    },
    {
        path: PATH_SUPERADMIN.manage.notifications,
        element: (
            <SuperAdminGuard>
                <DashboardLayout title={'Manage / Notifications'}>
                    <SuperAdminNotificationsPage />
                </DashboardLayout>
            </SuperAdminGuard>
        ),
    },
    {
        path: PATH_SUPERADMIN.manage.buglist,
        element: (
            <SuperAdminGuard>
                <DashboardLayout title={'Manage / BugList'}>
                    <SuperAdminBuglistPage />
                </DashboardLayout>
            </SuperAdminGuard>
        ),
    },
] as RouteObject[]

export default SuperUserRoutes
