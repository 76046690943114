import { isEmpty, isString } from 'lodash'
import { format, formatISO, parse, parseISO, startOfDay } from 'date-fns'
import { store } from 'store'
import { isValidDate as validateDate } from './date'

export const dateFormat = 'MMM d, y'
export const dateTimeFormat = 'MMM d, y hh:mm:ss'

export const getDateFormat = () => store?.getState()?.company?.settings?.DateFormat || dateFormat

export const parseDate = (input: string, formatStr = '') => {
    if (isEmpty(input)) {
        return ''
    }
    const formatter = isEmpty(formatStr) ? getDateFormat() : formatStr
    return parse(input, formatter, new Date())
}

export const formatDate = (input: any, formatStr = ''): string => {
    if (isEmpty(input) && !(input instanceof Date)) {
        return ''
    }
    if (input instanceof Date) {
        if (input.getFullYear() === 1970) {
            return ''
        }
    }
    const formatter = isEmpty(formatStr) ? getDateFormat() : formatStr
    return format(isString(input) === true ? parseISO(input) : input, formatter.replace('MMMM', 'MMM') || dateFormat)
}

export const formatDateTime = (input: any, formatStr = ''): string => {
    const formatter = isEmpty(formatStr) ? store?.getState()?.company?.settings?.DateTimeFormat || dateTimeFormat : formatStr
    return formatDate(input, formatter || dateTimeFormat)
}

export const convertDatesToTiming = (dates_arr: any) => {
    const ranges: any = []
    const dates: any = []

    dates_arr.forEach((date: any) => {
        if (date.includes(' To ')) {
            const range = date.split(' To ')
            ranges.push({ From: new Date(range[0]).toISOString(), To: new Date(range[1]).toISOString() })
        } else {
            dates.push(new Date(date).toISOString())
        }
    })
    return { Ranges: ranges, Dates: dates }
}

export const convertTimingToDateArray = (dates_arr: any) => {
    const dates: any = []
    dates_arr?.Ranges?.forEach((date: any) => {
        dates.push(new Date(date.From).toISOString() + ' To ' + new Date(date.To).toISOString())
    })

    dates_arr?.Dates?.forEach((date: any) => {
        dates.push(new Date(date).toISOString())
    })

    return dates
}

/** it will return grinwich time */
export const dateWithoutOffset = (date: Date | null) => {
    if (!date) return date
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
}

/** this will return original value in case it is not date */
export const dateWithoutOffsetLoose = <T>(date: T) => (date instanceof Date ? dateWithoutOffset(date) : date)

/** it will set time to zero */
export const dateWithoutTime = (date: Date) => new Date(date.getFullYear(), date.getMonth(), date.getDate())

export const isValidDate = (date?: undefined | null | string | Date): boolean => {
    if (!date) return false // it is undefined or null
    if (date instanceof Date) {
        return validateDate(date)
    } // it is a Date
    return validateDate(parseISO(date)) // it is a string
}

export const isoDateWithoutMs = (date: string) => {
    if (date.indexOf('Z') > 19) return date.substring(0, 19) + 'Z'
    return date
}

export const toServerTime = (date?: undefined | null | Date) => {
    if (!isValidDate(date)) return undefined
    return formatISO(date as Date, { representation: 'date' }) + 'T00:00:00'
}

export const toServerTimes = (dates: Date[]) =>
    (dates || [])
        .filter((d) => d)
        .map((date) => toServerTime(startOfDay(date as Date)))
        .filter((i) => i)
