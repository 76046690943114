import React from 'react'
import { Skeleton } from '@mui/material'
import { Stack } from '@mui/system'

const SkeletonGenericPageLoading = () => (
    <Stack spacing={1}>
        <Skeleton
            animation='wave'
            variant='text'
            sx={{ fontSize: '3rem' }}
        />
        <br />
        <Skeleton
            variant='rounded'
            width={'100%'}
            height={'30vh'}
            animation='wave'
        />
        <br />
        <Skeleton
            variant='rounded'
            width={'100%'}
            height={'70vh'}
            animation='wave'
        />
    </Stack>
)

export default SkeletonGenericPageLoading
