import React, { useMemo } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { SectionComponentProps } from './type'
import { groupBy } from 'lodash'
import { SettingsAIInsightItemDetails } from '../../../@types/insights'
import { responsiveFontSizes } from '../../../utils/formatFontSize'

interface AIInsightsSectionProps extends Omit<SectionComponentProps, 'data'> {
    data: SettingsAIInsightItemDetails[]
    title?: string
}

interface AIInsightsSectionItemProps extends Omit<AIInsightsSectionProps, 'data'> {
    data: SettingsAIInsightItemDetails
}

const AISettingsSectionItem: React.FC<AIInsightsSectionItemProps> = ({ data, expanded, onChange }) => {
    return (
        <Accordion
            expanded={expanded}
            onChange={onChange}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant={'subtitle1'}>{data['Field name']}</Typography>
            </AccordionSummary>
            <AccordionDetails className={'word-wrap'}>
                <Box sx={{ ...responsiveFontSizes({ sm: 9, md: 10, lg: 12 }) }}>
                    {data.Details}
                    <br />
                    Recommendation: {data.Recommendation}
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

const AISettingsSection: React.FC<AIInsightsSectionProps> = ({ data, weeks, expanded, onChange, title = 'Assistant' }) => {
    const [page, setPage] = React.useState<Record<string, boolean>>({})

    const groupedData = useMemo(() => groupBy(data, 'Category'), [data])

    if (data.length === 0) {
        return <></>
    }

    return (
        <Accordion
            expanded={expanded}
            onChange={onChange}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant={'subtitle1'}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {Object.entries(groupedData).map(([title, items], index) => {
                    if (items.length > 1) {
                        return (
                            <AISettingsSection
                                data={items.map((i, index2) => ({ ...i, Category: i.Category + index2 }))}
                                title={title}
                                key={`inner-ai-${index}-${title}`}
                                expanded={page[`inner-ai-${index}-${title}`] === true}
                                onChange={() => setPage({ ...page, [`inner-ai-${index}-${title}`]: !page[`inner-ai-${index}-${title}`] })}
                                weeks={weeks}
                            />
                        )
                    }
                    return (
                        <AISettingsSectionItem
                            key={'AIInsightsSectionItem' + index}
                            data={items[0]}
                            weeks={weeks}
                            expanded={page[`ai-${index}`] === true}
                            onChange={() => setPage({ ...page, [`ai-${index}`]: !page[`ai-${index}`] })}
                        />
                    )
                })}
            </AccordionDetails>
        </Accordion>
    )
}

export default AISettingsSection

//
