import { addMinutes, addWeeks, endOfDay, endOfMonth, endOfWeek, getWeekOfMonth, parseISO, startOfDay, startOfMonth, startOfWeek } from 'date-fns'
import { filter, find, forEach, fromPairs, isEmpty, isString } from 'lodash'
import {
    GeographyHierarchyTree,
    GeographyItem,
    ImpactTimingRangeResponse,
    PartnerOrganizationItem,
    PartnerOrganizationTree,
    Stakeholder,
} from '../../../@types/impact'
import { CompanyHierarchyTree } from '../../../@types/settings'
import tinycolor from 'tinycolor2'
import { calculateSum } from 'utils/array'
import { WeeksInMonth } from '../../../@types/reports'

export function getXAxisDataPeriod(row: Record<string, any>) {
    if (isEmpty(row?.Periods)) {
        return getXAxisData(isString(row.From) ? parseISO(row.From) : row.From, isString(row.To) ? parseISO(row.To) : row.To)
    }

    try {
        const { Ranges, Dates } = JSON.parse(row.Periods)
        if (isEmpty(Ranges) && isEmpty(Dates)) {
            return getXAxisData(isString(row.From) ? parseISO(row.From) : row.From, isString(row.To) ? parseISO(row.To) : row.To)
        }
        return [
            ...Dates.map((date: string) => {
                const parsed = parseISO(date)
                return getXAxisData(addMinutes(startOfDay(parsed), 10), addMinutes(endOfDay(parsed), -10))
            }),
            ...Ranges.map((range: ImpactTimingRangeResponse) =>
                getXAxisData(addMinutes(startOfDay(parseISO(range.From)), 10), addMinutes(endOfDay(parseISO(range.To)), -10))
            ),
        ].flat()
    } catch (e) {
        console.log(e)
        return getXAxisData(isString(row.From) ? parseISO(row.From) : row.From, isString(row.To) ? parseISO(row.To) : row.To)
    }
}

export function getXAxisData(startDate: Date | null | undefined, endDate: Date | null | undefined, header = false): WeeksInMonth[] {
    const result: WeeksInMonth[] = []

    if (!startDate || !endDate) {
        return result
    }

    let dateStart = startOfWeek(addMinutes(startOfDay(startDate), 10), { weekStartsOn: 1 })
    if (dateStart.getMonth() !== startDate.getMonth()) {
        dateStart = startOfMonth(addMinutes(startOfDay(startDate), 10))
    }

    const res = []

    while (dateStart.getTime() < endDate.getTime()) {
        const endWeek = addMinutes(endOfDay(endOfWeek(dateStart, { weekStartsOn: 1 })), -10)

        let weekBreaks = dateStart.getMonth() !== endWeek.getMonth()
        // && dateStart.getFullYear() === endWeek.getFullYear()

        if (!header && weekBreaks && endDate.getTime() < endWeek.getTime()) {
            weekBreaks = false
        }

        const _w = getWeekOfMonth(dateStart, { weekStartsOn: 1 })
        res.push({
            year: dateStart.getFullYear(),
            month: dateStart.getMonth(),
            week: _w,
            from: dateStart,
            id: `${dateStart.getFullYear()}${dateStart.getMonth()}${_w}`,
            to: weekBreaks ? addMinutes(endOfDay(endOfMonth(dateStart)), -15) : endWeek,
        })

        if (weekBreaks) {
            const _ww = getWeekOfMonth(endWeek, { weekStartsOn: 1 })
            res.push({
                year: endWeek.getFullYear(),
                month: endWeek.getMonth(),
                week: _ww,
                from: startOfDay(startOfMonth(endWeek)),
                id: `${endWeek.getFullYear()}${endWeek.getMonth()}${_ww}`,
                to: endWeek,
            })
        }
        dateStart = startOfWeek(addWeeks(dateStart, 1), { weekStartsOn: 1 })
    }
    return res
}

const _levelColors = ['#C6DBEF', '#9ECAE1', '#6BAED6', '#38a9ff', '#3182BD', '#1b67c9', '#124587']

export const getCellColors = (colorIntensity = 0, levelColors: Array<string> = _levelColors) => {
    // error check
    if (colorIntensity < -5 || colorIntensity > 1) console.error(`getCellColor got ${colorIntensity}! colorIntensity range is from (-5) to (1)`)
    return levelColors.map((i: string) =>
        tinycolor(i)
            .darken(colorIntensity * 2.5)
            .toString()
    )
}

// colorIntensity lowers/raises color intensity (from -5 to 1)
export function getCellColor(weeklyHours: number, hoursRange: Array<number> = [], colorLevels: any[] = []): string {
    const colorIndex = colorLevels.length - 1
    /*for (let index = 0; index < hoursRange.length; index++) {
        const num = hoursRange[index]
        if (num === weeklyHours || (num > weeklyHours && weeklyHours < (hoursRange[index+1]||10000))) {
            colorIndex = index
            break
        }
    }*/

    if (weeklyHours < hoursRange[0] || weeklyHours > hoursRange[hoursRange.length - 1]) {
        return colorLevels[colorIndex] ///throw new Error('Value out of range');
    }

    let segmentIndex = 0
    for (let i = 1; i < hoursRange.length; i++) {
        if (weeklyHours < hoursRange[i]) {
            segmentIndex = i - 1
            break
        }
    }

    if (segmentIndex < 0) {
        segmentIndex = 0
    }

    const rangeStart = hoursRange[segmentIndex]
    const rangeEnd = hoursRange[segmentIndex + 1]
    // Get the start and end colors in hexadecimal values
    const ratio = (weeklyHours - rangeStart) / (rangeEnd - rangeStart)

    // Get start and end colors
    const colorStart = tinycolor(colorLevels[segmentIndex])
    const colorEnd = tinycolor(colorLevels[segmentIndex + 1])

    // Perform color mixing by ratio
    const mixedColor = tinycolor.mix(colorStart, colorEnd, ratio * 100)

    return mixedColor.toHexString() // Return the hex string of the color
    //return colors[colorIndex] || colors[colors.length - 1]
    /*
    console.log('index', colors.length, hoursRange, weeklyHours, index)
    if (weeklyHours === 0) return getCellColors(colorIntensity, levelColors)[0]
    else if (weeklyHours < 10) return getCellColors(colorIntensity, levelColors)[1]
    else if (weeklyHours < 25) return getCellColors(colorIntensity, levelColors)[2]
    else if (weeklyHours < 45) return getCellColors(colorIntensity, levelColors)[3]
    else if (weeklyHours < 55) return getCellColors(colorIntensity, levelColors)[4]
    else return getCellColors(colorIntensity, levelColors)[5]
    */
}

/*
    if (weeklyHours === 0) return getCellColors(colorIntensity)[0]
    else if (weeklyHours < 5) return getCellColors(colorIntensity)[1]
    else if (weeklyHours < 8) return getCellColors(colorIntensity)[2]
    else if (weeklyHours < 12) return getCellColors(colorIntensity)[3]
    else if (weeklyHours < 16) return getCellColors(colorIntensity)[4]
    else return getCellColors(colorIntensity)[5]
 */

export const heatmapDataByDivision = (
    hierarchyTree: CompanyHierarchyTree | Record<string, any>,
    data: Array<Record<string, any>>,
    filterState: Record<string, any>,
    autoExpand: boolean,
    weeks: WeeksInMonth[]
) => {
    const weekHours = fromPairs(weeks.map((i) => ['' + i.id, 0])) as Record<string, number>
    let rows: Record<string, any> = {}
    // filtering first
    let divisions: Record<string, any> = {}
    let subDivisions: Record<string, any> = {}
    let teams: Record<string, any> = {}

    // action planing special case
    const filterDivision = isEmpty(filterState?.filterNames) ? (filterState?.division || []).map((i: any) => i.Name) : filterState?.filterNames
    const filterSubDivision = isEmpty(filterState?.filterNames) ? (filterState?.subDivision || []).map((i: any) => i.Name) : filterState?.filterNames
    const filterTeam = isEmpty(filterState?.filterNames) ? (filterState?.team || []).map((i: any) => i.Name) : filterState?.filterNames
    /*eslint-disable */
    // eslint-disable-next-line
    data.forEach((i: any) => {
        const timeRange = getXAxisDataPeriod(i) // getXAxisData(i.From, i.To)
        /// console.log('timeRange', timeRange, i)
        const row = { ...i }

        if (row.ImpactType === 'Process') {
            if (row.ImpactLevel === 1) {
                row.WeeklyHours = 0.5
            } else if (row.ImpactLevel === 2) {
                row.WeeklyHours = 1.5
            } else if (row.ImpactLevel === 3) {
                row.WeeklyHours = 4
            } else if (row.ImpactLevel === 4) {
                row.WeeklyHours = 10
            } else if (row.ImpactLevel === 5) {
                row.WeeklyHours = 30
            }
        }

        i?.Division?.forEach((division: any) => {
            if (!isEmpty(filterDivision) && filterDivision.includes(division) === false) {
                return
            }
            divisions = {
                ...divisions,
                [division]: [...(divisions[division] || []), { ...row, impactRange: timeRange }],
            }
        })
        i?.SubDivision?.forEach((division: any) => {
            if (!isEmpty(filterSubDivision) && !filterSubDivision.includes(division)) {
                return
            }
            subDivisions = {
                ...subDivisions,
                [division]: [...(subDivisions[division] || []), { ...row, impactRange: timeRange }],
            }
        })
        i?.Team?.forEach((team: any) => {
            if (!isEmpty(filterTeam) && !filterTeam.includes(team)) {
                return
            }
            teams = {
                ...teams,
                [team]: [...(teams[team] || []), { ...row, impactRange: timeRange }],
            }
        })
    })

    hierarchyTree?.Division?.forEach((division: Record<string, any>) => {
        const divisionItems = divisions[division.Name] || []
        if (isEmpty(divisionItems) && !isEmpty(filterDivision)) {
            return
        }

        if (!Object.prototype.hasOwnProperty.call(rows, division.Name)) {
            rows = {
                ...rows,
                [division.Name]: {
                    items: divisionItems,
                    children: {},
                    level: 1,
                    maxWeekLyHours: MAX_WEEKLY_HOURS,
                    expanded: autoExpand || !isEmpty(filterState?.division),
                    coefficient: 1,
                    EmployeeCount: division.EmployeeCount,
                    weekHours: { ...weekHours },
                },
            }
        }

        const filteredSubDivisions = filter(hierarchyTree?.SubDivision, { ParentCompanyHierarchyItemId: division.Id }) || []
        const divisionEmployeeCount = division.EmployeeCount > 0 ? division.EmployeeCount : calculateSum(filteredSubDivisions, 'EmployeeCount')
        filteredSubDivisions.forEach((sub: any) => {
            const subItems = subDivisions[sub.Name] || []
            if (isEmpty(subItems) && !isEmpty(filterSubDivision)) {
                return
            }

            let coefficient = 1
            if (sub.EmployeeCount > 0 && divisionEmployeeCount > 0) {
                const percents = (sub.EmployeeCount * 100) / divisionEmployeeCount
                // if (percents > 10) {
                coefficient = percents //sub.EmployeeCount / divisionEmployeeCount
                // if ((sub.EmployeeCount + divisionEmployeeCount) / 2 < sub.EmployeeCount) coefficient *= 0.5
                // }
            }

            rows[division.Name].children = {
                ...rows[division.Name].children,
                [sub.Name]: {
                    items: subItems,
                    level: 2,
                    children: {},
                    maxWeekLyHours: MAX_WEEKLY_HOURS,
                    coefficient,
                    EmployeeCount: sub.EmployeeCount,
                    expanded: autoExpand || !isEmpty(filterState?.subDivision),
                    weekHours: { ...weekHours },
                },
            }

            const filteredTeams = filter(hierarchyTree?.Team, { ParentCompanyHierarchyItemId: sub.Id }) || []
            const subDivisionEmployeeCount = sub.EmployeeCount > 0 ? sub.EmployeeCount : calculateSum(filteredTeams, 'EmployeeCount')

            filteredTeams.forEach((team: any) => {
                const teamItems = teams[team.Name] || []
                if (isEmpty(teamItems) && !isEmpty(filterTeam)) {
                    return
                }

                let coefficient = 1
                if (team.EmployeeCount > 0 && subDivisionEmployeeCount > 0) {
                    const percents = (team.EmployeeCount * 100) / subDivisionEmployeeCount
                    // if (percents > 10) {
                    coefficient = percents /// team.EmployeeCount / subDivisionEmployeeCount
                    // if ((team.EmployeeCount + subDivisionEmployeeCount) / 2 < team.EmployeeCount) coefficient *= 0.5
                    // }
                }

                rows[division.Name].children[sub.Name].children = {
                    ...rows[division.Name].children[sub.Name].children,
                    [team.Name]: {
                        items: teamItems,
                        children: {},
                        level: 3,
                        maxWeekLyHours: MAX_WEEKLY_HOURS, // 25,
                        expanded: autoExpand || !isEmpty(filterState?.team),
                        coefficient,
                        EmployeeCount: team.EmployeeCount,
                        weekHours: { ...weekHours },
                    },
                }
            })
        })
    })

    return rows
    /*return Object.fromEntries(
        Object.entries(rows).map(([key, value]) => {
            // sub Divisions
            const newChildren = Object.fromEntries(
                Object.entries((value.children || {}) as Record<string, any>).map(([subDivision, _value]) => {
                    // teams
                    const teams =  Object.fromEntries(
                        Object.entries((_value?.children || {}) as Record<string, any> ).map(([team, val]) => {
                            const weekHours = { ...val.weekHours }
                              val.items.forEach((i: Record<string, any>) => {
                                  i.impactRange.forEach((r: Record<string, any>) => {
                                      weekHours[r.id] = weekHours[r.id] + i?.ImpactHours||i?.WeeklyHours
                                  })
                              })
                            return [team, { ...val, weekHours }]
                        })
                    )

                    const weekHours = { ..._value.weekHours }

                    const teamItems = Object.values(teams)
                    if (teamItems.length > 0) {
                        Object.keys(weekHours).forEach((key) => {
                            weekHours[key] = weekHours[key] + average(teamItems.map((i: Record<string, any>) => i.weekHours[key]))
                        })
                    } else {
                        _value.items.forEach((i: Record<string, any>) => {
                            i.impactRange.forEach((r: Record<string, any>) => {
                                weekHours[r.id] = weekHours[r.id] + i?.ImpactHours||i?.WeeklyHours
                            })
                        })
                    }
                    return [subDivision, { ..._value, weekHours, children: teams }] //
                })
            )
            const weekHours = { ...value.weekHours }
            const subDivisions = Object.values(newChildren)
            if (subDivisions.length > 0) {
                Object.keys(weekHours).forEach((key) => {
                    weekHours[key] = weekHours[key] + average(subDivisions.map((i: Record<string, any>) => i.weekHours[key]))
                })
            } else {
                value.items.forEach((i: Record<string, any>) => {
                    i.impactRange.forEach((r: Record<string, any>) => {
                        weekHours[r.id] = weekHours[r.id] + i?.ImpactHours||i?.WeeklyHours
                    })
                })
            }
            return [key, { ...value, children: newChildren, weekHours }]
        })
    )*/

    /*
        TOO OLD IMPLEMENTATION
    return rows Object.fromEntries(

        Object.entries(rows).map(([key, value]) => { // 4  = 1
            const newChildren = Object.fromEntries(
                Object.entries(value.children || []).map(([key, _value]) => {
                    // @ts-expect-error: NEED better Typescript descr
                    const coefficient =  calculateAvg(Object.values(_value.children || {}), 'coefficient', 0) //* 100 / MAX_WEEKLY_HOURS
                    // @ts-expect-error: NEED better Typescript descr
                    return [key, { ..._value, coefficient }] //
                })
            )
            const coefficient = calculateAvg(Object.values(newChildren), 'coefficient', 0) //* 100 / MAX_WEEKLY_HOURS
            return [key, {
                ...value,
                coefficient: coefficient,
                children: newChildren
            }]
        })
    )*/
}

const getPartnerSubItemNames = (items: PartnerOrganizationItem[], parentId: string): Record<string, PartnerOrganizationItem> =>
    fromPairs(items.filter((i: PartnerOrganizationItem) => i?.ParentOrganizationItemId === parentId).map((i: PartnerOrganizationItem) => [i.Name, i]))

export const MAX_WEEKLY_HOURS = 60

export const heatmapDataByGroupKey = (
    groupKey: string,
    data: Array<Record<string, any>>,
    filterState: Record<string, any>,
    partnerOrganizationTree: PartnerOrganizationTree,
    employeeStakeHolderList: Stakeholder[] = []
) => {
    let getMaxWorkingHours = (i: any) => MAX_WEEKLY_HOURS
    if (groupKey === 'Stakeholders') {
        const stakeHoldersMap = fromPairs(employeeStakeHolderList.map((i) => [i.Name, i.Hours]))
        getMaxWorkingHours = (i) => stakeHoldersMap[i] || MAX_WEEKLY_HOURS
    }
    // maxWeekLyHours
    let rows: Record<string, any> = {}
    data.forEach((i: Record<string, any>) => {
        if (i?.IsScenario === true) {
            return
        }
        if (groupKey === 'ImpactCustomerTypes' && i?.ImpactType !== 'Customer') {
            return
        }
        if (groupKey === 'PartnerType' && i?.ImpactType !== 'Partner' && i?.ImpactType !== 'System') {
            return
        }
        const row = { ...i }
        if (row.ImpactType === 'Customer' || row.ImpactType === 'Partner' || row.ImpactType === 'System' || row.ImpactType === 'Process') {
            if (row.ImpactLevel === 0) {
                row.WeeklyHours = 0.0
            } else if (row.ImpactLevel === 1) {
                row.WeeklyHours = 0.5
            } else if (row.ImpactLevel === 2) {
                row.WeeklyHours = 1.5
            } else if (row.ImpactLevel === 3) {
                row.WeeklyHours = 4
            } else if (row.ImpactLevel === 4) {
                row.WeeklyHours = 10
            } else if (row.ImpactLevel === 5) {
                row.WeeklyHours = 30
            }
        }
        const rowItems = groupKey === 'Initiatives' ? [row.Initiatives] : row[groupKey] || []

        const filterPartnerName = (filterState?.partnerOrgNames || []).map((w: PartnerOrganizationItem) => w?.Name)
        const filterPartnerType = (filterState?.partnerOrgTypes || []).map((w: PartnerOrganizationItem) => w?.Name)
        const filterPartnerTeam = (filterState?.partnerOrgTeams || []).map((w: PartnerOrganizationItem) => w?.Name)

        forEach(rowItems, (item: string) => {
            const key = '' + (item || '')

            if (groupKey === 'PartnerType' && !isEmpty(filterPartnerType) && filterPartnerType.includes(key) === false) {
                return
            }

            if (!Object.prototype.hasOwnProperty.call(rows, key)) {
                rows = {
                    ...rows,
                    [key]: {
                        items: [],
                        level: 1,
                        maxWeekLyHours: getMaxWorkingHours(key),
                        children: {},
                    },
                }
            }
            //const timeRange = getXAxisDataPeriod(i) // getXAxisData(i.From, i.To)
            const rowItem = { ...row } as Record<string, any>
            ;(rows[key]?.items || []).push(rowItem)
            if (groupKey === 'PartnerType') {
                let children = { ...(rows[key]?.children || {}) } as Record<any, any>

                const partnerType = find(partnerOrganizationTree.PartnerType, { Name: key })
                const partnerNames = getPartnerSubItemNames(partnerOrganizationTree.PartnerName, partnerType?.Id || '-') as Record<
                    string,
                    PartnerOrganizationItem
                >

                ;(row?.PartnerName || []).forEach((i: string) => {
                    if (!isEmpty(filterPartnerName) && filterPartnerName.includes(i) === false) {
                        return
                    }
                    const v = children[i]?.items || []
                    if (partnerNames[i]) {
                        const partnerTeam = getPartnerSubItemNames(partnerOrganizationTree.PartnerTeam, partnerNames[i].Id) as Record<
                            string,
                            PartnerOrganizationItem
                        >
                        let subChildren = {} as Record<any, any>
                        ;(row?.PartnerTeam || []).forEach((team: string) => {
                            const tItems = (children[i]?.children || {})[team]?.items || []
                            if (!isEmpty(filterPartnerTeam) && filterPartnerTeam.includes(team) === false) {
                                return
                            }
                            if (partnerTeam[team]) {
                                subChildren = {
                                    ...subChildren,
                                    [team]: {
                                        items: [...tItems, rowItem],
                                        children: {},
                                    },
                                }
                            }
                        })
                        children = { ...children, [i]: { items: [...v, rowItem], children: { ...subChildren } } }
                    }
                })
                rows = { ...rows, [key]: { ...(rows[key] || {}), children } }
            }
        })
    })
    return rows
}

const getGeographyParent = (items: GeographyItem[], item: GeographyItem) => (items || []).filter((i: GeographyItem) => i.ParentGeographyItemId === item.Id)

export const heatmapDataByEmployeeGeography = (
    impactGeographyTree: GeographyHierarchyTree,
    data: Array<Record<string, any>>,
    filterState: Record<string, any>
) => {
    let rows = {} as {
        [key: string]: {
            items: Array<Record<string, any>>
            children: {
                [key: string]: {
                    items: Array<Record<string, any>>
                    children: {
                        [key: string]: {
                            items: Array<Record<string, any>>
                            //children: {},
                            expanded: false
                            coefficient: 1
                        }
                    }
                    expanded: false
                    coefficient: 1
                }
            }
            expanded: false
            coefficient: 1
        }
    }
    // .filter((i) => i.ImpactType === 'Employee')
    data.forEach((item) => {
        const rowItem = { ...item } as Record<string, any>
        ;(item?.EmpGeographyRegion || []).forEach((name: string) => {
            const region = find(impactGeographyTree.Region, { Name: name })
            if (!region) {
                return
            }
            if (!Object.prototype.hasOwnProperty.call(rows, region.Name)) {
                rows = {
                    ...rows,
                    [region.Name]: {
                        items: [rowItem],
                        children: {},
                        expanded: false,
                        coefficient: 1,
                    },
                }
            } else {
                rows = {
                    ...rows,
                    [region.Name]: { ...rows[region.Name], items: [...rows[region.Name].items, rowItem] },
                }
            }
            const networkOffices = getGeographyParent(impactGeographyTree.NetworkOffice, region)
            if (networkOffices.length === 0) {
                return
            }
            ;(item?.EmpGeographyNetworkOffice || []).forEach((name: string) => {
                const networkOffice = find(networkOffices, { Name: name })
                if (!networkOffice) {
                    return
                }

                if (!Object.prototype.hasOwnProperty.call(rows[region.Name].children, networkOffice.Name)) {
                    rows = {
                        ...rows,
                        [region.Name]: {
                            ...rows[region.Name],
                            children: {
                                ...rows[region.Name].children,
                                [networkOffice.Name]: {
                                    items: [rowItem],
                                    children: {},
                                    expanded: false,
                                    coefficient: 1,
                                },
                            },
                        },
                    }
                } else {
                    const childrenItem = rows[region.Name].children[networkOffice.Name]
                    rows = {
                        ...rows,
                        [region.Name]: {
                            ...rows[region.Name],
                            children: {
                                ...rows[region.Name].children,
                                [networkOffice.Name]: {
                                    ...childrenItem,
                                    items: [...childrenItem.items, rowItem],
                                },
                            },
                        },
                    }
                }
                const districtOffices = getGeographyParent(impactGeographyTree.DistrictOffice, networkOffice)
                if (districtOffices.length === 0) {
                    return
                }
                ;(item?.EmpGeographyDistrictOffice || []).forEach((name: string) => {
                    const districtOffice = find(districtOffices, { Name: name })
                    if (!districtOffice) {
                        return
                    }
                    const networkOfficeItem = rows[region.Name].children[networkOffice.Name]
                    if (!Object.prototype.hasOwnProperty.call(networkOfficeItem, districtOffice.Name)) {
                        rows = {
                            ...rows,
                            [region.Name]: {
                                ...rows[region.Name],
                                children: {
                                    ...rows[region.Name].children,
                                    [networkOffice.Name]: {
                                        ...networkOfficeItem,
                                        children: {
                                            ...networkOfficeItem.children,
                                            [districtOffice.Name]: {
                                                items: [rowItem],
                                                expanded: false,
                                                coefficient: 1,
                                            },
                                        },
                                    },
                                },
                            },
                        }
                    } else {
                        rows = {
                            ...rows,
                            [region.Name]: {
                                ...rows[region.Name],
                                children: {
                                    ...rows[region.Name].children,
                                    [networkOffice.Name]: {
                                        ...networkOfficeItem,
                                        children: {
                                            ...networkOfficeItem.children,
                                            [districtOffice.Name]: {
                                                ...networkOfficeItem.children[districtOffice.Name],
                                                items: [networkOfficeItem.children[districtOffice.Name].items, rowItem],
                                            },
                                        },
                                    },
                                },
                            },
                        }
                    }
                })
            })
        })
    })

    return rows
}
