// https://css-tricks.com/snippets/javascript/lighten-darken-color/
import tinycolor from 'tinycolor2'

export const lightenDarkenColorArray = (colArray: string[], amt: number) => {
    if (amt === 0) return colArray
    return colArray.map((col) => tinycolor(col).darken(amt).toString()) ///lightenDarkenColor(col, amt))
}

export const lightenDarkenColor = (col: string, amt: number): string => tinycolor(col).darken(amt).toString()

export const colorRange = (color: string, amount: number) =>
    tinycolor(color)
        .analogous(amount)
        .map((i) => i.toString())

export const colors = [
    '#BD7150',
    '#7B4F71',
    '#0F5C55',
    '#168980',
    '#4AC5BB',
    '#a7d3ff',
    '#b66dff',
    '#FDAB89',
    '#475052',
    '#6A9FB0',
    '#B687AC',
    '#d3e9fe',
    '#00ffad',
    '#1B4D5C',
    '#074650',
    '#A78F8F',
    '#fe6db6',
    '#feb5da',
    '#5F6B6D',
    '#b5dafe',
    '#28738A',
    '#009292',
    '#B59525',
    '#FB8281',
    '#706060',
    '#6db6ff',
    '#A4DDEE',
    '#c58aff',
    '#480091',
    '#F4D25A',
    '#BD7150',
    '#914800',
    '#293537',
    '#23fd23',
    '#1C2325',
]
