import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Lists(theme: Theme) {
    return {
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingRight: '2 !important',
                    paddingLeft: '2 !important',
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(2),
                },
            },
        },
        MuiListItemAvatar: {
            styleOverrides: {
                root: {
                    minWidth: 'auto',
                    marginRight: theme.spacing(2),
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    '@media (max-width:1300px)': {
                        lineHeight: '10px',
                        height: '38px !important',
                    },
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    marginTop: 0,
                    marginBottom: 0,
                },
                multiline: {
                    marginTop: 0,
                    marginBottom: 0,
                },
            },
        },
    }
}
