import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ScaleProps } from '../../../@types/impact'
import axiosInstance, { responseData } from 'utils/axios'
import { reportError } from 'utils/errorReport'
import { createSelector } from 'reselect'
import { RootState } from '../../store'

interface ScalesProps {
    isLoading: boolean
    error: boolean
    scales: ScaleProps[]
}

const initialState: ScalesProps = {
    isLoading: false,
    error: false,
    scales: [],
}

const slice = createSlice({
    name: 'scales',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        // GET PRODUCTS
        getScaleSuccess(state, action) {
            state.isLoading = false
            state.scales = action.payload
        },
    },
})

export default slice.reducer

export const getScales = createAsyncThunk('thunk/fetchImpactScales', async (_, { dispatch }) => {
    dispatch(slice.actions.startLoading())
    try {
        const response = await axiosInstance.post('/api/ImpactScale/GetImpactScaleList')
        const res = responseData(response.data)
        dispatch(slice.actions.getScaleSuccess(responseData(response.data)))
        return res
    } catch (error) {
        reportError(error)
        dispatch(slice.actions.hasError(error))
    }
    return []
})

export const getScalesList = createSelector(
    (state: RootState) => state.impact.scales,
    (scales): ScaleProps[] => scales.scales
)
