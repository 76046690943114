import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import { RootState } from '../../store'
import { fetchResponseData } from '../../../utils/axios'
import { BenefitInitiativeData, BenefitReportData, BenefitsReportItemResponse, BenefitsReportResponse } from '../../../@types/reports'
import { parseISO } from 'date-fns'
import { createSelector } from 'reselect'
import { find } from 'lodash'
import { toServerTime } from '../../../utils/formatDate'

const initialState = {
    isLoading: false,
    error: false,
    data: {
        benefitMilestones: [],
        benefitsData: [],
        initiativeData: [],
    } as BenefitReportData,
    filtersData: {
        initiatives: [],
        companyStrategy: [],
        phase: [],
        owner: [],
        changeType: [],
        activities: [],
        stakeholders: [],
        contact: [],
        tags: [],
    },
}
/*
{
    "Id": "13cb3a16-6c95-44f7-b20f-036c9818e5bd",
    "Name": "Auto_Test_milestone_in_benefit 37211",
    "Description": "",
    "TypeId": "f5aa313a-9e7d-4208-a122-bf22d9c84b7c",
    "TypeIds": [
        "f5aa313a-9e7d-4208-a122-bf22d9c84b7c"
    ],
    "InitiativeDivisionIds": [],
    "PrimaryContact": "Test",
    "Owner": "Adam M",
    "IsVerified": true,
    "IsArchived": false,
    "InitiativePhaseId": "c7b19bdd-ec6a-44c1-941f-729e870652d1",
    "ParticipatingUsers": [],
    "ParticipatingUsersIds": [],
    "InitiativeStrategicAlignmentsIds": [],
    "InitiativeDivisionThemesIds": [],
    "WithImpactlvl0": false,
    "DivisionId": "",
    "EditableFieldValue": [],
    "UseScenarios": false,
    "ScenarioInitiatives": [],
    "DeleteWhenScenarioExpired": false,
    "EditableFieldOptionIds": null,
    "TagIds": [],
    "ChangeReadiness": 0,
    "Priority": 0,
    "AdoptionRating": null
}
 */
const slice = createSlice({
    name: 'benefitsChart',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
            state.error = false
        },
        stopLoading(state) {
            state.isLoading = false
        },
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },
        getBenefitChartSuccess(state, action) {
            state.isLoading = false
            state.data = action.payload
        },
        setFilterData(state, action) {
            state.filtersData = action.payload
        },
    },
})

export default slice.reducer

//const benefitsSource = axios.CancelToken.source()

export function getBenefitChartData(startDate: Date | null, endDate: Date | null) {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(slice.actions.hasError(null))
        dispatch(slice.actions.startLoading())
        try {
            const requestData =
                startDate && endDate
                    ? {
                          fromDate: toServerTime(startDate),
                          endDate: toServerTime(endDate),
                      }
                    : {}
            const { benefitMilestones, benefitsData, initiativeData } = (await fetchResponseData('/api/Report/ReportBenefits', requestData)) as {
                benefitMilestones: BenefitsReportResponse[]
                benefitsData: BenefitsReportItemResponse[]
                initiativeData: BenefitInitiativeData[]
            }

            const initiatives = new Set()
            const companyStrategy = new Set()
            const phase = new Set()
            const owner = new Set()
            const changeType = new Set()
            const activities = new Set()
            const stakeholders = new Set()
            const contact = new Set()
            const tags = new Set()

            const { initiativeStrategicAlignments, initiativePhases } = getState().initiative

            const newInitiativesData = [] as BenefitInitiativeData[]

            ;(initiativeData || []).forEach((item: BenefitInitiativeData) => {
                initiatives.add(item.Name)
                item.InitiativeStrategicAlignmentsIds.forEach((type) => {
                    const alignment = find(initiativeStrategicAlignments, { Id: type })
                    if (alignment) {
                        companyStrategy.add(alignment.Name)
                    }
                })
                // companyStrategy.add(item.)

                phase.add(item.InitiativePhaseId)
                owner.add(item.Owner)
                item.TypeIds.forEach((type) => companyStrategy.add.bind(type))
                activities.add(item.Priority)
                stakeholders.add(item.PrimaryContact)
                contact.add(item.PrimaryContact)
                item.TagIds.forEach((tag) => tags.add.bind(tag))

                newInitiativesData.push({
                    ...item,
                    InitiativeName: item.Name,
                    Phase: find(initiativePhases, { Id: item.InitiativePhaseId })?.Name || '',
                    Contact: item.PrimaryContact,
                    ChangeType: item.TypeIds,
                    CompanyStrategy: item.InitiativeStrategicAlignmentsIds.map((type) => find(initiativeStrategicAlignments, { Id: type })?.Name || ''),
                    DivisionThemes: item.InitiativeDivisionThemesIds,
                })
            })

            dispatch(
                slice.actions.setFilterData({
                    initiatives: Array.from(initiatives).sort(),
                    companyStrategy: Array.from(companyStrategy).sort(),
                    phase: Array.from(phase).sort(),
                    owner: Array.from(owner).sort(),
                    changeType: Array.from(changeType).sort(),
                    activities: Array.from(activities).sort(),
                    stakeholders: Array.from(stakeholders).sort(),
                    contact: Array.from(contact).sort(),
                    tags: Array.from(tags).sort(),
                })
            )

            dispatch(
                slice.actions.getBenefitChartSuccess({
                    initiativeData: newInitiativesData,
                    benefitMilestones: (benefitMilestones || []).map((item: BenefitsReportResponse) => ({
                        ...item,
                        MilestoneDate: parseISO(item.MilestoneDate),
                    })),
                    benefitsData: (benefitsData || []).map((item: BenefitsReportItemResponse) => ({
                        ...item,
                        EstimatedRealizationDate: item.EstimatedRealizationDate ? parseISO(item.EstimatedRealizationDate) : null,
                    })),
                })
            )
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        } finally {
            dispatch(slice.actions.stopLoading())
        }
    }
}

export const getBenefitFiltersData = createSelector(
    (state: RootState) => state.reports.benefitsChart,
    (state): BenefitReportData => state.filtersData
)

export const getBenefitReportData = createSelector(
    (state: RootState) => state.reports.benefitsChart,
    (state): BenefitReportData => state.data
)

export const isLoadingBenefitReport = createSelector(
    (state: RootState) => state.reports.benefitsChart,
    (state): boolean => state.isLoading
)

export const getErrorBenefitReport = createSelector(
    (state: RootState) => state.reports.benefitsChart,
    (state): string | null => state.error
)
