import React from 'react'
import { useSnackbar, VariantType } from 'notistack'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { MIconButton } from 'components/@material-extend'

const useClosableSnackbar = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const closableEnqueueSnackbar = (message: string | React.ReactNode, variant: VariantType | string, autoHideDuration?: number | null) => {
        enqueueSnackbar(message, {
            variant: variant as VariantType,
            preventDuplicate: true,
            autoHideDuration: autoHideDuration !== undefined ? autoHideDuration : 5 * 1000,
            action: (key) => (
                <MIconButton
                    size='small'
                    onClick={() => closeSnackbar(key)}
                >
                    <CloseOutlinedIcon />
                </MIconButton>
            ),
            style: { whiteSpace: 'pre-line', zIndex: 999999999999999 },
        })
    }

    return {
        closableEnqueueSnackbar,
        errorEnqueueSnackbar: (message: string | React.ReactNode, autoHideDuration?: number | null) =>
            closableEnqueueSnackbar(message, 'error', autoHideDuration),
        warningEnqueueSnackbar: (message: string | React.ReactNode, autoHideDuration?: number | null) =>
            closableEnqueueSnackbar(message, 'warning', autoHideDuration),
        infoEnqueueSnackbar: (message: string | React.ReactNode, autoHideDuration?: number | null) =>
            closableEnqueueSnackbar(message, 'info', autoHideDuration),
        successEnqueueSnackbar: (message: string | React.ReactNode, autoHideDuration?: number | null) =>
            closableEnqueueSnackbar(message, 'success', autoHideDuration),
    }
}

export default useClosableSnackbar
