import { createSlice } from '@reduxjs/toolkit'
import axiosInstance, { getSandboxInstance, responseData } from 'utils/axios'
import { Dispatch } from 'redux'
import { isSandBox } from 'store/slices/settings'
import { addMinutes, endOfDay, format, parseISO, startOfDay } from 'date-fns'
import { reportError } from 'utils/errorReport'
import { RootState } from 'store'
import axios from 'axios'
import { createSelector } from 'reselect'

const initialState = {
    isLoading: false,
    error: false,
    data: {},
    filtersData: {
        initiatives: [],
        companyStrategy: [],
        phase: [],
        owner: [],
        changeType: [],
        activities: [],
        stakeholders: [],
        contact: [],
        partnerStakeholders: [],
        partnerImpactScales: [],
    },
}

const slice = createSlice({
    name: 'timeLineChart',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
            state.error = false
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        // GET PRODUCTS
        getTimeLineSuccess(state, action) {
            state.isLoading = false
            state.data = action.payload
        },
        setTimeLineFilterData(state, action) {
            state.filtersData = action.payload
        },
    },
})

export default slice.reducer

let totalImpactAxiosSource = axios.CancelToken.source()

export function getTimeLineReport(startDate: Date | null, endDate: Date | null) {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(slice.actions.getTimeLineSuccess({}))
        dispatch(slice.actions.startLoading())
        totalImpactAxiosSource.cancel()
        totalImpactAxiosSource = axios.CancelToken.source()
        try {
            const response = await (isSandBox(getState()) ? await getSandboxInstance() : axiosInstance).get('/api/Report/ReportTotalImpact', {
                params: {
                    impactFromDate: format(startDate || new Date(), 'y-MM-d'),
                    impactToDate: format(endDate || new Date(), 'y-MM-d'),
                    useDefaultDiv: false,
                },
                cancelToken: totalImpactAxiosSource.token,
            })
            const data = responseData(response.data)

            const initiatives = new Set()
            const companyStrategy = new Set()
            const phase = new Set()
            const owner = new Set()
            const changeType = new Set()
            const activities = new Set()
            const stakeholders = new Set()
            const partnerStakeholders = new Set()
            const partnerImpactScales = new Set()
            const contact = new Set()

            const impactData = (data?.impactData || []).map((i: Record<string, any>) => {
                const impact = {
                    ...i,
                    From: addMinutes(startOfDay(parseISO(i.From)), 10),
                    To: addMinutes(endOfDay(parseISO(i.To)), -10),
                } as Record<string, any>
                initiatives.add(impact.Initiatives)
                impact.CompanyStrategy?.forEach(companyStrategy.add.bind(companyStrategy))
                phase.add(impact.Phase)
                owner.add(impact.Owner)
                /// Stakeholders
                impact.ChangeType.forEach(changeType.add.bind(changeType))
                contact.add(impact.Contact)
                impact.Activities.forEach(activities.add.bind(activities))
                impact.Stakeholders.forEach(stakeholders.add.bind(stakeholders))
                impact.PartnerStakeholder?.forEach(partnerStakeholders.add.bind(partnerStakeholders))
                partnerImpactScales.add(impact?.PartnerImpactScaleId)
                return impact
            })

            dispatch(
                slice.actions.setTimeLineFilterData({
                    initiatives: Array.from(initiatives).sort(),
                    companyStrategy: Array.from(companyStrategy).sort(),
                    phase: Array.from(phase).sort(),
                    owner: Array.from(owner).sort(),
                    changeType: Array.from(changeType).sort(),
                    activities: Array.from(activities).sort(),
                    stakeholders: Array.from(stakeholders).sort(),
                    contact: Array.from(contact).sort(),
                    partnerStakeholders: Array.from(partnerStakeholders).sort(),
                    partnerImpactScales: Array.from(partnerImpactScales).filter((i) => i),
                })
            )

            dispatch(slice.actions.getTimeLineSuccess({ ...data, impactData }))
        } catch (error) {
            if (axios.isCancel(error)) {
                return
            }
            reportError(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}

export const getTimeLineFiltersData = createSelector(
    (state: RootState) => state.reports.timeLineReport,
    (timeLineReport): Record<string, any> => timeLineReport.filtersData
)
