import React, { useEffect, useRef } from 'react'
import { ChatTextMessage } from '../../@types/chat'
import Scrollbar from 'components/Scrollbar'
import styled from '@mui/system/styled'
import { Box, Typography } from '@mui/material'
import { formatDistanceToNowStrict } from 'date-fns'
import { formatDate } from '../../utils/formatDate'
import { responsiveFontSizes } from '../../utils/formatFontSize'
import { isEmpty } from 'lodash'
import { generatePath, Link } from 'react-router-dom'
import { PATH_INITIATIVES } from '../../routes/paths'

type Props = {
    conversation: ChatTextMessage[]
}

const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(3),
}))

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 320,
    padding: theme.spacing(1.5),
    marginTop: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.neutral,
}))

const InfoStyle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(0.75),
    color: theme.palette.text.secondary,
}))

const SplitIntoWords = /([a-z])([A-Z])/g

const GuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i

const AIMessage = ({ answer, details }: { answer: string; details: Record<string, any>[] }) => {
    const renderValue = (key: any, value: any) => {
        if (['LastModifiedAt', 'From', 'To'].includes(key)) {
            return formatDate(value)
        }
        return value
    }

    const renderDetails = (data: Record<string, any>) => {
        const { LastModifiedAt, Description, initiativeId, ...details } = data
        return (
            <ul style={{ listStyle: 'none', ...responsiveFontSizes({ sm: 10, md: 12, lg: 14 }) }}>
                {Object.entries(details).map(([key, value]) => {
                    if (isEmpty(value)) {
                        return null
                    }
                    if (key === 'initiative' && initiativeId) {
                        return (
                            <li key={key + value}>
                                <Link
                                    style={{ color: '#1b67c9' }}
                                    target={'_blank'}
                                    to={generatePath(PATH_INITIATIVES.actions.addEdit, { initiativeId: initiativeId })}
                                >
                                    {value}
                                </Link>
                            </li>
                        )
                    }
                    if (GuidRegex.test(value)) {
                        const splited = []
                        let id = ''
                        for (const i of value.split(value) || []) {
                            if (GuidRegex.test(i)) {
                                id = i
                            } else {
                                splited.push(i)
                            }
                        }
                        return (
                            <Link
                                key={key + value}
                                style={{ color: '#1b67c9' }}
                                target={'_blank'}
                                to={generatePath(PATH_INITIATIVES.actions.addEdit, { initiativeId: id })}
                            >
                                {splited.join(' ')}
                            </Link>
                        )
                    }
                    return (
                        <li key={key + value}>
                            <b>{key.replace(SplitIntoWords, '$1 $2')}</b>:{renderValue(key, value)}
                        </li>
                    )
                })}
            </ul>
        )
    }

    return (
        <>
            {answer}
            <br />
            {details.map((i: Record<string, any>) => renderDetails(i))}
        </>
    )
}

const ChatMessageList: React.FC<Props> = ({ conversation }) => {
    const scrollRef = useRef<HTMLDivElement>(null)
    // conversation

    useEffect(() => {
        const scrollMessagesToBottom = () => {
            if (scrollRef.current) {
                scrollRef.current.scrollIntoView()
            }
        }
        scrollMessagesToBottom()
    }, [conversation])

    const renerMessageBody = (chatMessage: ChatTextMessage) => {
        if (chatMessage.contentType === 'text') {
            return chatMessage.body as string
        }
        // @ts-expect-error: NEED better Typescript descr
        const { answer, details } = chatMessage.body as { answer: string; details: any[] }
        return (
            <AIMessage
                answer={answer}
                details={details}
            />
        )
    }

    const renderMessage = (chatMessage: ChatTextMessage) => {
        const isMe = chatMessage.senderId !== 'ai'

        return (
            <RootStyle>
                <Box
                    sx={{
                        display: 'flex',
                        ...(isMe && {
                            ml: 'auto',
                        }),
                    }}
                >
                    <div>
                        <InfoStyle
                            variant='caption'
                            sx={{
                                ...(isMe && { justifyContent: 'flex-end' }),
                            }}
                        >
                            {formatDistanceToNowStrict(new Date(chatMessage.createdAt), {
                                addSuffix: true,
                            })}
                        </InfoStyle>

                        <ContentStyle
                            sx={{
                                ...(isMe && { color: 'grey.800', bgcolor: 'primary.lighter' }),
                            }}
                        >
                            <Typography variant='body2'>{renerMessageBody(chatMessage)}</Typography>
                        </ContentStyle>
                    </div>
                </Box>
            </RootStyle>
        )
    }

    return (
        <>
            <Scrollbar sx={{ p: 3, height: 1, maxHeight: '75vh' }}>
                {conversation.map(renderMessage)}
                <Box ref={scrollRef}></Box>
            </Scrollbar>
        </>
    )
}
export default ChatMessageList
