import { EffectCallback, useEffect, useRef, useState } from 'react'
import { fetchResponseData } from '../utils/axios'
import { without } from 'lodash'
import { reportError } from '../utils/errorReport'
import useSnackbar from './useClosableSnackbar'

/** effect procs only one time and after first argument is true */
const useEffectIf = (trigger: boolean, effect: EffectCallback) => {
    const isTriggered = useRef(false)
    useEffect(() => {
        if (trigger && !isTriggered.current) {
            isTriggered.current = true
            return effect()
        }
        // eslint-disable-next-line
    }, [trigger])
}

export const useQueryGet = <T>(
    shortName: string,
    url: string,
    transform: (result: any) => T = (result) => result,
    options?: {
        trigger?: boolean
    }
) => {
    // result
    const [result, setResult] = useState<T>()
    // indicators
    const [isLoading, setLoading] = useState(true)
    const [isError, setError] = useState(false)
    const { closableEnqueueSnackbar } = useSnackbar()

    useEffectIf(options?.trigger ?? true, () => {
        fetchResponseData(url)
            .then((response) => {
                setResult(transform(response))
            })
            .catch((error) => {
                reportError(error)
                setError(true)
                closableEnqueueSnackbar(`Failed to load ${shortName}`, 'error')
            })
            .finally(() => setLoading(false))
    })

    return {
        result,
        isLoading,
        isError,
        fixResult: setResult,
    }
}

/** allows to edit Array state
 * inside of async functions */
const useStateArray = <T>(initialArray: T[] = []) => {
    const [_, redraw] = useState(initialArray)
    const ref = useRef(initialArray)

    return {
        value: ref.current,
        set: (array: T[]) => {
            ref.current = array
            redraw(ref.current)
            return ref.current
        },
        delete: (arrayItem: T) => {
            ref.current = without(ref.current, arrayItem)
            redraw(ref.current)
            return ref.current
        },
        deleteMany: (arrayItems: T[]) => {
            ref.current = ref.current.filter((oldValue) => !arrayItems.includes(oldValue))
            redraw(ref.current)
            return ref.current
        },
        add: (arrayItem: T) => {
            ref.current = [...ref.current, arrayItem]
            redraw(ref.current)
            return ref.current
        },
        addMany: (arrayItems: T[]) => {
            ref.current = [...ref.current, ...arrayItems]
            redraw(ref.current)
            return ref.current
        },
        update: (pair: [current: T, toUpdate: T]) => {
            // copy
            ref.current = [...ref.current]
            // find && replace
            const index = ref.current.indexOf(pair[0])
            if (index > -1) ref.current[index] = pair[1]
            // redraw react
            redraw(ref.current)
            return ref.current
        },
        updateAll: (pairs: [current: T, toUpdate: T][]) => {
            // copy
            ref.current = [...ref.current]
            // find && replace
            pairs.forEach((pair) => {
                const index = ref.current.indexOf(pair[0])
                if (index > -1) ref.current[index] = pair[1]
            })
            // redraw react
            redraw(ref.current)
            return ref.current
        },
    }
}

export const useQueryGetMutableArray = <T>(
    shortName: string,
    url: string,
    transform: (result: any[]) => T[] = (result) => result,
    options?: {
        trigger?: boolean
        /** return false to prevent removing */
        onDelete?: (arrayItem: T) => Promise<boolean> | boolean
        /** return false to prevent adding */
        onAdd?: (arrayItem: T) => Promise<boolean> | boolean
        /** return false to prevent updating */
        onUpdate?: (pair: [current: T, toUpdate: T]) => Promise<boolean> | boolean
        /** return false to prevent update all */
        onUpdateAll?: (pairs: [current: T, toUpdate: T][]) => Promise<boolean> | boolean
    }
) => {
    // result
    const result = useStateArray<T>()
    // array indicators
    const adding = useStateArray<T>([])
    const updating = useStateArray<[current: T, toUpdate: T]>([])
    const removing = useStateArray<T>([])
    // indicators
    const [isLoading, setLoading] = useState(true)
    const [isError, setError] = useState(false)
    const { closableEnqueueSnackbar } = useSnackbar()

    useEffectIf(options?.trigger ?? true, () => {
        fetchResponseData(url)
            .then((response) => {
                result.set(transform(response as any[]))
            })
            .catch((error) => {
                reportError(error)
                setError(true)
                closableEnqueueSnackbar(`Failed to load ${shortName}`, 'error')
            })
            .finally(() => setLoading(false))
    })

    return {
        result: result.value,
        isLoading,
        isError,
        // indicators for removing / adding items
        adding: adding.value,
        updating: updating.value,
        removing: removing.value,
        // imperative removing of items
        delete: async (arrayItem: T) => {
            removing.add(arrayItem)
            if (options?.onDelete) {
                const res = await options.onDelete(arrayItem)
                if (res === true) {
                    result.delete(arrayItem)
                } else {
                    closableEnqueueSnackbar(res || 'Failed to delete item', 'error')
                }
            }

            removing.delete(arrayItem)
        },
        add: async (arrayItem: T) => {
            adding.add(arrayItem)

            if (!options?.onAdd || (await options.onAdd(arrayItem))) result.add(arrayItem)
            else closableEnqueueSnackbar('Failed to add item', 'error')

            adding.delete(arrayItem)
        },
        /**
         * pair[0] is current value
         * pair[1] is value to update
         */
        update: async (pair: [current: T, toUpdate: T]) => {
            updating.add(pair)

            if (!options?.onUpdate || (await options.onUpdate(pair))) result.update(pair)
            else closableEnqueueSnackbar('Failed to update item', 'error')

            updating.delete(pair)
        },
        /**
         *
         */
        updateAll: async (pairs: [current: T, toUpdate: T][]) => {
            updating.addMany(pairs)
            if (!options?.onUpdateAll || (await options.onUpdateAll(pairs))) result.updateAll(pairs)
            else closableEnqueueSnackbar('Failed to update all items', 'error')

            updating.deleteMany(pairs)
        },
    }
}
