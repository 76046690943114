import { createSlice } from '@reduxjs/toolkit'
import axiosInstance, { getSandboxInstance, responseData } from 'utils/axios'
import { Dispatch } from 'redux'
import { isSandBox } from '../settings'
import { format } from 'date-fns'
import { reportError } from 'utils/errorReport'
import { RootState } from 'store'
import axios from 'axios'

const initialState = {
    isLoading: false,
    error: false,
    data: {},
}

const slice = createSlice({
    name: 'readinessChart',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
            state.error = false
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        // GET PRODUCTS
        getReadinessSuccess(state, action) {
            state.isLoading = false
            state.error = false
            state.data = action.payload
        },
    },
})

export default slice.reducer

let impactReadinessAxiosSource = axios.CancelToken.source()

export function getReadinessReport(startDate: Date | null, endDate: Date | null) {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(slice.actions.getReadinessSuccess({}))
        dispatch(slice.actions.startLoading())
        impactReadinessAxiosSource.cancel()
        impactReadinessAxiosSource = axios.CancelToken.source()
        try {
            const response = await (isSandBox(getState()) ? await getSandboxInstance() : axiosInstance).post(
                '/api/Report/ImpactReadinessData',
                {},
                {
                    params: {
                        impactFromDate: format(startDate || new Date(), 'y-MM-d'),
                        impactToDate: format(endDate || new Date(), 'y-MM-d'),
                        request: 'd',
                    },
                    cancelToken: impactReadinessAxiosSource.token,
                }
            )
            const data = responseData(response.data)
            // InitiativeName
            dispatch(slice.actions.getReadinessSuccess({ ...data }))
        } catch (error) {
            if (axios.isCancel(error)) {
                return
            }
            reportError(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}
