import React from 'react'
import { Navigate, RouteObject } from 'react-router-dom'
import { PATH_ACTION_PLANING, PATH_ANALYTICS, PATH_AUDIT_PLANING, PATH_DASHBOARD, PATH_PREDICTION } from './paths'
// layouts
import AuthGuard from 'guards/AuthGuard'
import DashboardLayout from 'layouts/dashboard'
import { lazyLoad } from '../utils/routes'

const DashboardPage = lazyLoad(() => import('views/dashboard/index'), 'DashboardPage')
const TestPageFilterPage = lazyLoad(() => import('views/TestPageFilter'), 'TestPageFilter')
const ActionPlaningPage = lazyLoad(() => import('views/actionPlaning/index'), 'ActionPlaningPage')
const ZohoAnalyticsPage = lazyLoad(() => import('views/ZohoAnalyticsPage'), 'ZohoAnalyticsPage')
const AuditPage = lazyLoad(() => import('views/audit/index'), 'AuditPage')
const PredictionPage = lazyLoad(() => import('views/prediction/index'), 'PredictionPage')

const DashboardRoutes = [
    {
        path: '/',
        element: <Navigate to={PATH_DASHBOARD.root} />,
    },
    {
        path: '/welcome',
        element: <Navigate to={PATH_DASHBOARD.root} />,
    },
    {
        path: PATH_DASHBOARD.home,
        element: <Navigate to={PATH_DASHBOARD.root} />,
    },
    {
        path: PATH_ACTION_PLANING.root,
        element: (
            <AuthGuard>
                <DashboardLayout title={'Action Planing'}>
                    <ActionPlaningPage />
                </DashboardLayout>
            </AuthGuard>
        ),
    },
    {
        path: PATH_ANALYTICS.root,
        element: (
            <AuthGuard>
                <DashboardLayout title={'Analytics+'}>
                    <ZohoAnalyticsPage />
                </DashboardLayout>
            </AuthGuard>
        ),
    },
    {
        path: PATH_AUDIT_PLANING.root,
        element: (
            <AuthGuard>
                <DashboardLayout title={'Data Audit'}>
                    <AuditPage />
                </DashboardLayout>
            </AuthGuard>
        ),
    },
    {
        path: PATH_PREDICTION.root,
        element: (
            <AuthGuard>
                <DashboardLayout title={'Prediction Page'}>
                    <PredictionPage />
                </DashboardLayout>
            </AuthGuard>
        ),
    },
    {
        path: PATH_DASHBOARD.root,
        element: (
            <AuthGuard>
                <DashboardLayout title={'Dashboard'}>
                    <DashboardPage />
                </DashboardLayout>
            </AuthGuard>
        ),
    },
    {
        path: '/internal',
        element: (
            <AuthGuard>
                <DashboardLayout title={'Internal'}>
                    <TestPageFilterPage />
                </DashboardLayout>
            </AuthGuard>
        ),
    },
] as RouteObject[]

export default DashboardRoutes
