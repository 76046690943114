import { createSlice } from '@reduxjs/toolkit'
import axiosInstance, { getSandboxInstance, responseData } from 'utils/axios'
import { Dispatch } from 'redux'
import { isSandBox } from '../settings'
import { addMinutes, endOfDay, format, parseISO, startOfDay } from 'date-fns'
import { sortBy } from 'lodash'
import { reportError } from 'utils/errorReport'
import { RootState } from 'store'
import axios from 'axios'

const initialState = {
    isLoading: false,
    error: false,
    data: {},
}

const slice = createSlice({
    name: 'strategyImpactChart',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
            state.error = false
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        // GET PRODUCTS
        getStrategyImpactChartSuccess(state, action) {
            state.isLoading = false
            state.data = action.payload
        },
    },
})

export default slice.reducer

let bubbleAxiosSource = axios.CancelToken.source()

export function getStrategyImpactChart(startDate: Date | null, endDate: Date | null) {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(slice.actions.getStrategyImpactChartSuccess({}))
        dispatch(slice.actions.startLoading())
        bubbleAxiosSource.cancel()
        bubbleAxiosSource = axios.CancelToken.source()
        try {
            const response = await (isSandBox(getState()) ? await getSandboxInstance() : axiosInstance).get('/api/Report/BubbleData', {
                params: {
                    impactFromDate: format(startDate || new Date(), 'y-MM-d'),
                    impactToDate: format(endDate || new Date(), 'y-MM-d'),
                    useDefaultDiv: false,
                },
                cancelToken: bubbleAxiosSource.token,
            })
            const data = responseData(response.data) || { bubbles: [] }

            dispatch(
                slice.actions.getStrategyImpactChartSuccess({
                    ...data,
                    bubbles: data.bubbles.map((bubble: Record<string, any>) => {
                        const { Impacts = [], ...rest } = bubble
                        return {
                            ...rest,
                            Impacts: sortBy(
                                Impacts.map((i: any) => ({
                                    ...i,
                                    ...rest,
                                    From: addMinutes(startOfDay(parseISO(i.ImpactFromDate)), 10),
                                    To: addMinutes(endOfDay(parseISO(i.ImpactToDate)), -10),
                                    Division: i.DivisionNames,
                                    ImpactScale: i.ImpactScale,
                                    Team: i.TeamNames,
                                    Initiatives: bubble.InitiativeName,
                                    ChangeType: bubble?.ChangeTypes || [],
                                    Contact: bubble?.ContactName || '',
                                    Phase: bubble?.InitiativePhaseName || '',
                                    DivisionStrategy: bubble?.DivisionStrategy || [],
                                })),
                                'From'
                            ),
                        }
                    }),
                })
            )
        } catch (error) {
            if (axios.isCancel(error)) {
                return
            }
            reportError(error)
            dispatch(slice.actions.getStrategyImpactChartSuccess({ bubbles: [] }))
            dispatch(slice.actions.hasError(error))
        }
    }
}
