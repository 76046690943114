import React, { forwardRef, useEffect, useMemo } from 'react'
import useAmplitude from 'hooks/useAmplitude'
import { Helmet } from 'react-helmet-async'
// material
import { Box, BoxProps, IconButton } from '@mui/material'

import InsightsIcon from '@mui/icons-material/Insights'
import { GridContainer, GridItem } from 'components/GridElements'
import Insights from 'views/insights'
import Scrollbar from 'components/Scrollbar'
import { DateRange } from 'components/DateRangePicker'

// ----------------------------------------------------------------------

interface PageProps extends BoxProps {
    children: React.ReactNode
    title?: string
    section?: string
    insights?: boolean
    dateRange?: DateRange<Date> | null | undefined
}

const Page = forwardRef<any, PageProps>(({ children, title = '', section = '', dateRange = null, ...other }, ref) => {
    const { logEvent } = useAmplitude()

    const [showInsights, setShowInsights] = React.useState(false)

    useEffect(() => {
        const eventTitle = title /// section ? `${title} ${section}` : title
        logEvent('Page Visit', { title: eventTitle, section })
    }, [title, logEvent])

    const renderedChildren = useMemo(() => <>{children}</>, [children])

    const renderInsights = () => {
        // isTestAccount(user)
        if (showInsights) {
            return (
                <GridContainer>
                    <GridItem>
                        <Scrollbar
                            sx={{ height: '76vh' }}
                            forceVisible={true}
                        >
                            {renderedChildren}
                        </Scrollbar>
                    </GridItem>
                    <GridItem xs={3}>
                        <Scrollbar
                            sx={{ height: '76vh' }}
                            forceVisible={true}
                        >
                            <Insights
                                dateRange={dateRange}
                                closeHandler={() => setShowInsights(!showInsights)}
                            />
                        </Scrollbar>
                    </GridItem>
                </GridContainer>
            )
        }
        return (
            <>
                <IconButton
                    sx={{ float: 'right', mb: '10px', cursor: 'pointer' }}
                    title={'Turn on Insights'}
                    onClick={(_e: any) => setShowInsights(!showInsights)}
                >
                    <InsightsIcon />
                </IconButton>
                {renderedChildren}
            </>
        )
    }

    const isShowInsights = false //insights

    return (
        <Box
            ref={ref}
            {...other}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>
            {isShowInsights && renderInsights()}
            {!isShowInsights && renderedChildren}
        </Box>
    )
})

Page.displayName = 'Page'

export default Page
