import React from 'react'
import { RouteObject } from 'react-router-dom'
import { PATH_AUTH } from 'routes/paths'

import AuthGuard from 'guards/AuthGuard'
import GuestGuard from 'guards/GuestGuard'
import { lazyLoad } from '../utils/routes'

const LoginPage = lazyLoad(() => import('views/authentication/Login'), 'LoginPage')
const RegisterPage = lazyLoad(() => import('views/authentication/Register/Register'), 'RegisterPage')
const VerifyPage = lazyLoad(() => import('views/authentication/VerifyEmail'), 'VerifyPage')
const SamlPage = lazyLoad(() => import('views/authentication/SamlLogin'), 'SamlPage')
const ResetPasswordPage = lazyLoad(() => import('views/authentication/ResetPassword'), 'ResetPasswordPage')
const ChangePasswordPage = lazyLoad(() => import('views/authentication/ChangePassword'), 'ChangePasswordPage')
const SubscribePasswordPage = lazyLoad(() => import('views/authentication/Register/Subscribe'), 'SubscribePasswordPage')
const RegisterCompletedPage = lazyLoad(() => import('views/authentication/Completed'), 'RegisterCompletedPage')
const LogOutPage = lazyLoad(() => import('views/authentication/Logout'), 'LogOutPage')

const AuthRoutes = [
    {
        path: PATH_AUTH.login,
        element: (
            <GuestGuard>
                <LoginPage />
            </GuestGuard>
        ),
    },
    {
        path: PATH_AUTH.register,
        element: (
            <GuestGuard>
                <RegisterPage />
            </GuestGuard>
        ),
    },
    {
        path: PATH_AUTH.verify,
        element: (
            <GuestGuard>
                <VerifyPage />
            </GuestGuard>
        ),
    },
    {
        path: PATH_AUTH.saml,
        element: (
            <GuestGuard>
                <SamlPage />
            </GuestGuard>
        ),
    },
    {
        path: PATH_AUTH.resetPassword,
        element: (
            <GuestGuard>
                <ResetPasswordPage />
            </GuestGuard>
        ),
    },
    {
        path: PATH_AUTH.changePassword,
        element: (
            <GuestGuard>
                <ChangePasswordPage />
            </GuestGuard>
        ),
    },
    {
        path: PATH_AUTH.subscribe,
        element: (
            <GuestGuard>
                <SubscribePasswordPage />
            </GuestGuard>
        ),
    },
    {
        path: PATH_AUTH.completed,
        element: (
            <GuestGuard>
                <RegisterCompletedPage />
            </GuestGuard>
        ),
    },
    {
        path: PATH_AUTH.logout,
        element: (
            <AuthGuard>
                <LogOutPage />
            </AuthGuard>
        ),
    },
] as RouteObject[]

export default AuthRoutes
