import { useRoutes } from 'react-router-dom'

import MainRoutes from './main.routes'
import DashboardRoutes from 'routes/dashboard.routes'
import AuthRoutes from 'routes/auth.routes'
import InitiativesRoutes from 'routes/initiative.routes'
import ReportsRoute from 'routes/reports.routes'
import SettingsRoutes from 'routes/settings.routes'
import SuperUserRoutes from 'routes/superadmin.routes'

const Routes = [...MainRoutes, ...AuthRoutes, ...InitiativesRoutes, ...DashboardRoutes, ...ReportsRoute, ...SettingsRoutes, ...SuperUserRoutes]

export default function Router() {
    return useRoutes(Routes)
}
