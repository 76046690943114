export const pillow =
    'M5.315 5.315C4.242 6.402 1.527 4.21 0 4.21s-4.242 2.178 -5.315 1.105C-6.402 4.242 -4.21 1.527 -4.21 0s-2.178 -4.242 -1.105 -5.315 3.802 1.105 5.315 1.105 4.242 -2.178 5.315 -1.105C6.402 -4.242 4.21 -1.527 4.21 0s2.178 4.242 1.105 5.315'

export const drop =
    'M-0.269 5.385c-0.377 -0.054 -0.646 -0.108 -0.969 -0.162C-2.531 4.846 -3.554 3.877 -3.877 2.692c-0.162 -0.377 -0.162 -0.915 -0.108 -1.238 0.162 -0.969 1.077 -2.692 2.585 -4.9C-0.915 -4.146 -0.054 -5.385 0 -5.385c0 0 0.377 0.485 0.754 1.023C2.423 -2.046 3.554 -0.054 3.931 1.077c0.054 0.323 0.108 0.538 0.108 0.808q-0.081 1.292 -0.969 2.262C2.423 4.792 1.508 5.223 0.538 5.331c-0.162 0 -0.7 0.054 -0.808 0.054'

export const minus = 'M -10,-3 H 10 V 3 H -10 Z'

export const vbar = 'M -3,10 V -10 H 3 V 10 Z'

export const clover =
    'M-0.071 -7c-4.357 0.071 -3.571 2.857 -1.857 5.143 -3 -0.143 -6 0.714 -3.786 4.143 2.071 3.286 4.216 1.643 5.571 -0.714l-0.929 2.571L-2.427 7l2.5 -0.216 2.571 0.143 -1.429 -2.857 -1 -2.5c1.357 2.357 3.571 4 5.571 0.643 2.143 -3.5 -0.857 -4.286 -3.857 -4.143C3.571 -4.216 4.286 -7.071 -0.071 -7'

export const cloud =
    'M1.6 -3.92c-1.44 0 -2.64 0.96 -3.12 2.4 -0.16 -0.08 -0.32 -0.08 -0.48 -0.08 -0.96 0 -1.68 0.8 -1.68 1.84 -0.16 -0.08 -0.4 -0.16 -0.64 -0.16 -0.88 0 -1.6 0.64 -1.68 1.44 -0.88 0.16 -1.6 0.96 -1.6 1.84 0 1.04 0.8 1.84 1.84 1.84H5.6c1.04 0 1.84 -0.8 1.84 -1.84 0 -0.56 -0.24 -1.04 -0.64 -1.44v-0.16C6.96 0.64 6.08 -0.4 4.88 -0.56 4.8 -2.4 3.36 -3.92 1.6 -3.92'

export const pin =
    'M0 -6.089c-2.286 0 -4.073 1.667 -4.073 3.782 0 0.384 0.071 0.704 0.143 1.025l0.572 1.025 3.286 6.345L3.144 0l0.857 -1.538c0.072 -0.256 0.072 -0.513 0.072 -0.768 0 -2.116 -1.787 -3.782 -4.073 -3.782M-0.143 -3.973a1.286 1.218 0 0 1 1.286 1.218 1.286 1.218 0 0 1 -1.286 1.282 1.286 1.218 0 0 1 -1.286 -1.282A1.286 1.218 0 0 1 -0.143 -3.973'

export const cresent =
    'M1.066 -5.334A5.334 5.334 0 0 0 -4.266 0a5.334 5.334 0 0 0 5.333 5.334 5.336 5.336 0 0 0 3.2 -1.066 4.24 4.24 0 0 1 -1.066 0A4.266 4.266 0 0 1 -1.066 0.001 4.266 4.266 0 0 1 3.2 -4.266a4.24 4.24 0 0 1 1.066 0 5.336 5.336 0 0 0 -3.2 -1.066'

export const sheild =
    'M 12.123424,3.09708 C 9.1750899,1.36849 7.0055019,1 7.0055019,1 c 0,0 -0.0023,3.5e-4 -0.0055,9.6e-4 C 6.9967019,1.00035 6.9945019,1 6.9945019,1 c 0,0 -2.169706,0.36849 -5.117921,2.09708 0,0 -0.390942,8.72672 5.117921,9.90292 0.0019,-3.5e-4 0.0036,-9.6e-4 0.0055,-10e-4 0.0019,3.5e-4 0.0036,9.6e-4 0.0055,10e-4 C 12.514247,11.8238 12.123424,3.09708 12.123424,3.09708 Z M 7.0058749,1.74098 c 0.482647,0.10437 2.177431,0.53826 4.3878041,1.77816 -0.0096,1.61164 -0.285353,7.71006 -4.3878041,8.73494 l 0,-10.5131 0,0 z'
