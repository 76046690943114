import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ChatTextMessage } from '../../@types/chat'
import { createSelector } from 'reselect'
import { RootState } from '../store'
import axiosInstance from '../../utils/axios'
import { generateUUID } from 'utils/string'

interface AiChartStateProps {
    messages: ChatTextMessage[]
}

const initialState: AiChartStateProps = {
    messages: [],
}

const slice = createSlice({
    name: 'aichart',
    initialState,
    reducers: {
        addMessage(state, action) {
            state.messages.push(action.payload)
        },
        clearMessages(state) {
            state.messages = []
        },
    },
})

export default slice.reducer

export const aiMessages = createSelector(
    (state: RootState) => state.aichart,
    (aichart): ChatTextMessage[] => aichart.messages
)

export const askAIRequest = createAsyncThunk('thunk/aichartAsk', async (message: any, { dispatch, rejectWithValue }) => {
    dispatch(
        slice.actions.addMessage({
            id: generateUUID(),
            body: message,
            contentType: 'text',
            createdAt: new Date(),
            senderId: 'user',
        })
    )

    try {
        const { data } = await axiosInstance.post('/api/chat/result', { question: message })

        const { answer, details } = data || {}
        dispatch(
            slice.actions.addMessage({
                id: generateUUID(),
                body: {
                    answer,
                    details: details || [],
                },
                contentType: 'dict',
                createdAt: new Date(),
                senderId: 'ai',
            })
        )
    } catch (err) {
        return rejectWithValue(err)
    }
})
