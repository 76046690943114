import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconPlus = (props: SvgIconProps) => (
    <SvgIcon
        width='1em'
        height='1em'
        {...props}
        preserveAspectRatio='xMidYMid meet'
        viewBox='0 0 24 24'
    >
        <path
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeWidth='2'
            d='M12 20v-8m0 0V4m0 8h8m-8 0H4'
        />
    </SvgIcon>
)
