import React from 'react'
import { Stack } from '@mui/system'
import { Skeleton } from '@mui/material'

const SkeletonAppLoading = () => (
    <Stack spacing={1}>
        <Skeleton
            animation='wave'
            variant='text'
            sx={{ fontSize: '3rem' }}
        />
    </Stack>
)

export default SkeletonAppLoading
