import { useEffect } from 'react'
import { isProduction } from 'utils/general'
import { User } from '../@types/account'
import useAuth from './useAuth'
import { useAppSelector } from './useAppRedux'
import { getCompanySettings } from '../store/slices/company'

function isValidAmplitudeInstance(maybeInstance: any) {
    return !!maybeInstance && typeof maybeInstance.init === 'function' && typeof maybeInstance.logEvent === 'function'
}

const AMPLITUDE_KEY = 'ce5bb6ceec0e7f963e765c8fee6b6cdf'

let amplitudeInstance: any = null

const logEvent = isProduction()
    ? (eventType: string, data?: any) => {
          try {
              amplitudeInstance?.logEvent(eventType, data)
          } catch (_err) {
              if (!isValidAmplitudeInstance(amplitudeInstance)) {
                  initAmplitude(() => {
                      logEvent(eventType, data)
                  })
              }
          }
      }
    : (_eventType: string, _data?: any) => {
          // do nothing
      }

const initAmplitude = (callback?: VoidFunction) => {
    import('amplitude-js')
        .then((amplitude) => {
            if (isProduction()) {
                amplitudeInstance = amplitude.default.getInstance()
                amplitudeInstance.init(AMPLITUDE_KEY)
            }
        })
        .catch((error) => {
            console.log(error)
            initAmplitude(callback)
        })
}

const setUserData = (user: User, compantName: string) => {
    if (isValidAmplitudeInstance(amplitudeInstance)) {
        amplitudeInstance.setUserId(user.email as string)
        amplitudeInstance.setGroup('Company', compantName)
        amplitudeInstance.setUserProperties({ ...user, CompanyProfile: compantName })
    }
}

export const initUserAmplitude = (user: User, compantName: string) => {
    if (isValidAmplitudeInstance(amplitudeInstance)) {
        initAmplitude(() => {
            setUserData(user, compantName)
        })
    }
    setUserData(user, compantName)
}

export default function useAmplitude() {
    const { user, isAuthenticated } = useAuth()
    const { Name } = useAppSelector(getCompanySettings)
    useEffect(() => {
        if (!isValidAmplitudeInstance(amplitudeInstance)) {
            initAmplitude()
        }
    }, [amplitudeInstance])

    useEffect(() => {
        if (isAuthenticated) {
            initUserAmplitude(user, Name)
        }
    }, [isAuthenticated, user, Name])

    return {
        logEvent: logEvent,
        logout: () => {
            ///context.amplitudeInstance?.clearStorage()
            //context.amplitudeInstance?.clearUserProperties()
        },
    }
}
