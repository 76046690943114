import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Divider, IconButton, Input } from '@mui/material'

import SendIcon from '@mui/icons-material/Send'
import { trim } from 'lodash'

// (({ theme }) => ({
const RootStyle = styled('div')(({ theme }) => ({
    minHeight: 56,
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
}))

type Props = {
    disabled: boolean
    conversationId: string | null
    onSend: (data: string) => void
}

const ChatMessageInput: React.FC<Props> = ({ disabled, onSend, conversationId = 'ai' }) => {
    const [message, setMessage] = useState('')

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSend()
        }
    }

    const handleSend = () => {
        if (!message) {
            return ''
        }
        if (onSend && conversationId) {
            onSend(message)
        }
        return setMessage('')
    }

    return (
        <RootStyle>
            <Input
                disabled={disabled}
                fullWidth
                value={message}
                disableUnderline={true}
                onKeyUp={handleKeyUp}
                onChange={(event) => setMessage(event.target.value)}
                placeholder='Ask a question'
            />

            <Divider
                orientation='vertical'
                flexItem
            ></Divider>

            <IconButton
                color='primary'
                disabled={!trim(message)}
                onClick={handleSend}
                sx={{ mx: 1 }}
            >
                <SendIcon />
            </IconButton>
        </RootStyle>
    )
}

export default ChatMessageInput
