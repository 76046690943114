import React, { forwardRef } from 'react'
// material
import { alpha, useTheme } from '@mui/material/styles'
import { IconButton, IconButtonProps } from '@mui/material'
//

// ----------------------------------------------------------------------

interface MIconButtonProps extends Omit<IconButtonProps, 'color'> {
    color?: 'inherit' | 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error'
}

const MIconButton = forwardRef<HTMLButtonElement, MIconButtonProps>(({ color = 'default', children, sx, ...other }, ref) => {
    const theme = useTheme()

    if (color === 'default' || color === 'inherit' || color === 'primary' || color === 'secondary') {
        return (
            <IconButton
                ref={ref}
                color={color}
                sx={sx}
                {...other}
                size='medium'
            >
                {children}
            </IconButton>
        )
    }

    return (
        <IconButton
            ref={ref}
            sx={{
                color: theme.palette[color].main,
                '&:hover': {
                    bgcolor: alpha(theme.palette[color].main, theme.palette.action.hoverOpacity),
                },
                ...sx,
            }}
            {...other}
            size='small'
        >
            {children}
        </IconButton>
    )
})

MIconButton.displayName = 'MIconButton'

export default MIconButton
