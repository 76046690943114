import React, { useEffect, useState } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { GridContainer, GridItem } from 'components/GridElements'
import { useAppDispatch, useAppSelector } from 'hooks/useAppRedux'
import { currentQuarterDateRange } from 'utils/date'
import InsightsSkeleton from 'components/SkeletonLoaders/InsightsSkeleton'
import DateRangePicker, { DateRange } from 'components/DateRangePicker'
import InsightsIcon from '@mui/icons-material/Insights'
import { fetchInsightsData, getAIInitativeInsightsData, getAISettingsInsightsData, isLoadingInsightsData } from 'store/slices/insights'
import AIInsightsSection from './sections/AIInsightsSection'
import AISettingsSection from './sections/AISettingsSection'
import usePrevious from 'hooks/usePrevious'
import { addMonths } from 'date-fns'

interface InsightsProps {
    closeHandler?: VoidFunction
    dateRange?: DateRange<Date> | null | undefined
}

const Insights = ({ closeHandler, dateRange }: InsightsProps) => {
    const dispatch = useAppDispatch()
    const isLoading = useAppSelector(isLoadingInsightsData)
    const initiativeAIData = useAppSelector(getAIInitativeInsightsData)
    const settingsAIData = useAppSelector(getAISettingsInsightsData)
    const [selectedDateRange, setSelectedDateRange] = useState<DateRange<Date>>(
        () => dateRange || [addMonths(currentQuarterDateRange[0] as Date, -4), currentQuarterDateRange[1]]
    )

    const [prev] = usePrevious<DateRange<Date>>(selectedDateRange)

    const [openedTabs, setOpenedTabs] = React.useState<Record<string, boolean>>({})

    useEffect(() => {
        const equal = prev.every((v, i) => v?.getTime() === selectedDateRange[i]?.getTime())
        if (isLoading || equal) {
            return
        }
        dispatch(fetchInsightsData(...selectedDateRange))
    }, [selectedDateRange])

    if (isLoading) return <InsightsSkeleton />

    return (
        <>
            <GridContainer>
                <GridItem>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                        <InsightsIcon /> &nbsp;&nbsp;
                        <Typography variant={'subtitle1'}> Insights</Typography>
                    </Box>

                    <DateRangePicker
                        dateRange={selectedDateRange}
                        onChange={(dateRange) => {
                            setSelectedDateRange(dateRange)
                        }}
                    />
                </GridItem>
                <GridItem xs={2}>
                    <IconButton
                        sx={{ position: 'relative', float: 'right' }}
                        title={'Close Insights'}
                        onClick={() => closeHandler && closeHandler()}
                    >
                        <CloseIcon />
                    </IconButton>
                </GridItem>
            </GridContainer>

            <AIInsightsSection
                title={'Initiative Insights'}
                data={initiativeAIData}
                expanded={openedTabs['AIInitiativeInsightsSection'] === true}
                onChange={() => setOpenedTabs({ ...openedTabs, AIInitiativeInsightsSection: !openedTabs['AIInitiativeInsightsSection'] })}
            />

            <AISettingsSection
                data={settingsAIData}
                title={'Settings Insights'}
                expanded={openedTabs['AISettingsInsightsSection'] === true}
                onChange={() => setOpenedTabs({ ...openedTabs, AISettingsInsightsSection: !openedTabs['AISettingsInsightsSection'] })}
            />
        </>
    )
}

export default Insights
