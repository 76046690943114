import React from 'react'
import { Navigate, RouteObject } from 'react-router-dom'
import { lazyLoad } from '../utils/routes'

const NotFoundPage = lazyLoad(() => import('views/Page404'), 'NotFoundPage')
const OldPage = lazyLoad(() => import('views/RedirectToOld'), 'OldPage')

const MainRoutes = [
    {
        path: '*',
        children: [
            { path: 'old', element: <OldPage /> },
            { path: '404', element: <NotFoundPage /> },
            {
                path: 'logout',
                element: (
                    <Navigate
                        to='/'
                        replace
                    />
                ),
            },
            {
                path: '*',
                element: (
                    <Navigate
                        to='/404'
                        replace
                    />
                ),
            },
        ],
    },
] as RouteObject[]

export default MainRoutes
