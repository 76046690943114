import React, { useState } from 'react'
import InsightsIcon from '@mui/icons-material/Insights'
import { Drawer, IconButton } from '@mui/material'
import Insights from '../../views/insights'
import Scrollbar from '../../components/Scrollbar'

const InsightsPopover = () => {
    const [showInsights, setShowInsights] = useState(false)

    return (
        <>
            <IconButton
                sx={{ float: 'right', mb: '0px', cursor: 'pointer' }}
                title={'Turn on Insights'}
                onClick={(_e: any) => setShowInsights(!showInsights)}
            >
                <InsightsIcon />
            </IconButton>

            <Drawer
                open={showInsights}
                anchor='right'
                onClose={() => setShowInsights(!showInsights)}
                sx={{ zIndex: 1999 }}
                PaperProps={{
                    sx: { minWidth: '30vw', maxWidth: '50vw', height: '100vh' },
                }}
            >
                <Scrollbar
                    sx={{ height: '100vh' }}
                    forceVisible={true}
                >
                    <Insights closeHandler={() => setShowInsights(!showInsights)} />
                </Scrollbar>
            </Drawer>
        </>
    )
}

export default InsightsPopover
