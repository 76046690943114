export const browserInfo = () => {
    const latitude = ''
    const longitude = ''
    ///const location = window.navigator && window.navigator.geolocation

    /* if (location) {
         location.getCurrentPosition(position => {
             latitude = position.coords.latitude
             longitude = position.coords.longitude
         })
     }*/

    return {
        timeOpened: new Date(),
        timezone: new Date().getTimezoneOffset() / 60,
        pageon: window.location.pathname,
        referrer: document.referrer,
        previousSites: window.history.length,
        browserName: window.navigator.appName,
        browserEngine: window.navigator.product,
        browserVersion1a: window.navigator.appVersion,
        browserVersion1b: navigator.userAgent,
        browserLanguage: navigator.language,
        browserOnline: navigator.onLine,
        browserPlatform: navigator.platform,
        javaEnabled: navigator.javaEnabled(),
        dataCookiesEnabled: navigator.cookieEnabled,
        dataCookies1: document.cookie,
        // @ts-expect-error: NEED better Typescript descr
        dataCookies2: decodeURIComponent(document.cookie.split(';')),
        dataStorage: localStorage,
        sizeScreenW: window.screen.width,
        sizeScreenH: window.screen.height,
        sizeInW: window.innerWidth,
        sizeInH: window.innerHeight,
        sizeAvailW: window.screen.availWidth,
        sizeAvailH: window.screen.availHeight,
        scrColorDepth: window.screen.colorDepth,
        scrPixelDepth: window.screen.pixelDepth,
        latitude,
        longitude,
    }
}

export function saveAs(uri: any, filename: string) {
    const link = document.createElement('a')

    if (typeof link.download === 'string') {
        link.href = uri
        link.download = filename
        //Firefox requires the link to be in the body
        document.body.appendChild(link)
        //simulate click
        link.click()
        //remove the link when done
        document.body.removeChild(link)
    } else {
        window.open(uri)
    }
}
