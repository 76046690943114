import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function MuiFormLabel(theme: Theme) {
    return {
        MuiFormLabel: {
            defaultProps: {
                shrink: true,
            },
            styleOverrides: {
                root: {
                    color: '#262c30', //theme.palette.primary,
                    '&$focused': {
                        color: theme.palette.primary,
                    },
                    marginTop: '-1px',
                    '@media (max-width:1300px)': {
                        marginTop: '2px',
                    },
                    ...theme.typography.body1,
                },
                focused: {
                    color: theme.palette.primary,
                },
            },
        },
    }
}
