import React, { LegacyRef } from 'react'
import SimpleBarReact, { Props } from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'
// material
import { alpha, styled } from '@mui/material/styles'
import { Box, BoxProps } from '@mui/material'

// ----------------------------------------------------------------------
const SimpleBarStyle = styled(SimpleBarReact)(({ theme }) => ({
    maxHeight: '100%',
    '& .simplebar-scrollbar': {
        '&:before': {
            transitionDelay: '500ms',
            backgroundColor: alpha(theme.palette.grey[600], 0.48),
        },
        '&.simplebar-visible:before': {
            opacity: 1,
        },
    },
    '& .simplebar-track.simplebar-vertical': {
        width: 10,
    },
    '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
        height: 10,
    },
    '& .simplebar-mask': {
        zIndex: 'inherit',
    },
}))

// ----------------------------------------------------------------------

export default function Scrollbar({ children, sx, ...other }: BoxProps & Props) {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

    if (isMobile) {
        return (
            <Box
                sx={{ overflowX: 'auto', ...sx }}
                {...other}
            >
                {children}
            </Box>
        )
    }

    const sx2 = {
        ...(sx || {}),
        '@media print': {
            height: 'auto !important',
            width: 'auto !important',
            maxHeight: 'none',
            overflow: 'hidden !important',
        },
    }

    return (
        <SimpleBarStyle
            clickOnTrack={false}
            sx={sx2}
            {...other}
            ref={other.ref as LegacyRef<any | null>}
        >
            {children}
        </SimpleBarStyle>
    )
}
