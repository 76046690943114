import React from 'react'
import PropTypes from 'prop-types'
import ErrorHandler from './ErrorHandler'
import Page from 'components/Page'
import Logo from 'components/Logo'

import { styled } from '@mui/material/styles'
// material
import { Box, Button, Container, Typography } from '@mui/material'
// components

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
    display: 'flex',
    minHeight: '100%',
    alignItems: 'center',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(10),
}))

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    left: 0,
    lineHeight: 0,
    width: '100%',
    position: 'absolute',
    padding: theme.spacing(3, 3, 0),
    [theme.breakpoints.up('sm')]: { padding: theme.spacing(5, 5, 0) },
}))

export default class PageErrorHandler extends ErrorHandler {
    renderError() {
        ///const { errorInfo } = this.state;
        return (
            <RootStyle title='Some Error occurred'>
                <HeaderStyle>
                    <Logo />
                </HeaderStyle>

                <Container>
                    <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
                        <Typography
                            variant='h3'
                            gutterBottom
                        >
                            There was an error
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>There was an error, please try again later.</Typography>

                        <Box
                            component='img'
                            alt='500'
                            src='/static/illustrations/illustration_500.svg'
                            sx={{ width: '100%', maxHeight: 240, my: { xs: 5, sm: 10 } }}
                        />

                        <Button
                            href='/'
                            size='large'
                            variant='contained'
                            onClick={() => {
                                window.location.reload()
                            }}
                        >
                            Reload this page
                        </Button>
                    </Box>
                </Container>
            </RootStyle>
        )
    }
}

PageErrorHandler.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
}
