import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance, { responseData } from 'utils/axios'
import { reportError } from 'utils/errorReport'
import { Activity } from '../../../@types/impact'
import { filterEmpty } from '../../../utils/array'
import { sortBy, uniqBy } from 'lodash'
import { createSelector } from 'reselect'
import { RootState } from '../../store'

interface ActivityState {
    isLoading: boolean
    error: boolean
    activities: Activity[]
}

const initialState: ActivityState = {
    isLoading: false,
    error: false,
    activities: [],
}

const slice = createSlice({
    name: 'activity',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        // GET PRODUCTS
        getActivitySuccess(state, action) {
            state.isLoading = false
            state.activities = action.payload
        },
    },
})

export default slice.reducer

export const getActivity = createAsyncThunk('thunk/fetchImpactActivities', async (_, { dispatch }) => {
    dispatch(slice.actions.startLoading())
    try {
        const response = await axiosInstance.post('/api/ImpactActivity/ImpactActivityList')
        const res = filterEmpty(responseData(response.data))

        const activities = sortBy(uniqBy(res as Activity[], 'Id'), (i: Activity) => i.DisplayOrder)
        dispatch(slice.actions.getActivitySuccess(activities))
        return activities
    } catch (error) {
        reportError(error)
        dispatch(slice.actions.hasError(error))
    }
    return []
})
/*export function getActivity() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await axiosInstance.post('/api/ImpactActivity/ImpactActivityList')
            const res = filterEmpty(responseData(response.data))

            const activities = sortBy(uniqBy((res as Activity[]), 'Id'), (i: Activity) => i.DisplayOrder)
            dispatch(
                slice.actions.getActivitySuccess(
                    activities
                )
            )
            return activities
        } catch (error) {
            reportError(error)
            dispatch(slice.actions.hasError(error))
        }
        return []
    }
}*/

export const getActivities = createSelector(
    (state: RootState) => state.impact.activity,
    (activity): Activity[] => activity.activities
)
