import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { Theme } from '@mui/material/styles'
import { responsiveFontSizes } from 'utils/formatFontSize'

// ----------------------------------------------------------------------
// eslint-disable-next-line
export default function Select(theme: Theme) {
    return {
        MuiSelect: {
            defaultProps: {
                IconComponent: ExpandMoreRoundedIcon,
            },
            styleOverrides: {
                select: {
                    '@media (max-width:1400px)': {
                        padding: '0px 7px !important',
                    },
                    ...responsiveFontSizes({ sm: 9, md: 9, lg: 12 }),
                },
            },
        },
    }
}
