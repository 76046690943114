import React, { CSSProperties, forwardRef } from 'react'

import './styles.css'

type Props = {
    style?: CSSProperties | undefined
    children?: React.ReactNode
}

const BouncingDotsLoader = forwardRef<HTMLDivElement, Props>(({ style, children }, ref) => {
    return (
        <>
            <div
                ref={ref}
                className='bouncing-loader'
                style={style}
            >
                <div></div>
                <div></div>
                <div></div>
            </div>
            {children}
        </>
    )
})
BouncingDotsLoader.displayName = 'BouncingDotsLoader'

export default BouncingDotsLoader
