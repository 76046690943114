import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { RootState } from '../../store'
import { Dispatch } from 'redux'
import { fetchResponseData, getErrorMessage, getResponseData } from '../../../utils/axios'
import { toServerTime } from '../../../utils/formatDate'
import { ChangeCalendarData, ChangeCalendarItem } from '../../../@types/settings'
import { isSameDay, parseISO } from 'date-fns'
import { findIndex } from 'lodash'
import { objectEquals } from 'utils/array'

interface initialStateProps {
    isLoading: boolean
    error: null | string
    data: ChangeCalendarItem[]
}

const initialState: initialStateProps = {
    isLoading: false,
    error: null,
    data: [],
}

const slice = createSlice({
    name: 'changeCalendar',
    initialState,
    reducers: {
        setLoading(state, action) {
            state.isLoading = action.payload
        },
        setError(state, action) {
            state.error = action.payload
        },
        setData(state, action) {
            state.data = action.payload
        },
    },
})

export default slice.reducer

export function fetchChangeCalendarData(startDate: Date | null, endDate: Date | null) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.setLoading(true))
        try {
            const data = await fetchResponseData('/api/CompanySettings/GetCalendarReportDates', {
                params: {
                    fromDate: toServerTime(startDate),
                    endDate: toServerTime(endDate),
                },
            })
            const items = (data as ChangeCalendarData[]).map((item: Record<string, any>) => {
                return {
                    ...item,
                    CreatedAt: parseISO(item.CreatedAt),
                    Date: parseISO(item.Date),
                }
            })

            dispatch(slice.actions.setData(items))
        } catch (e) {
            dispatch(slice.actions.setError(getErrorMessage(e)))
            dispatch(slice.actions.setData([]))
        } finally {
            dispatch(slice.actions.setLoading(false))
        }
    }
}

export const getChangeCalendarData = createSelector(
    (state: RootState) => state.reports.changeCalendar,
    (state): ChangeCalendarItem[] => state.data
)

export const isLoadingChangeCalendar = createSelector(
    (state: RootState) => state.reports.changeCalendar,
    (state): boolean => state.isLoading
)

export const getChangeCalendarLoadingError = createSelector(
    (state: RootState) => state.reports.changeCalendar,
    (state): null | string => state.error
)

export const getChangeCalendarfilterData = createSelector(
    (state: RootState) => state.reports.changeCalendar,
    (state): Record<string, unknown> => state.filterData
)

export interface ItemsToSave {
    DivisionId: null | string
    SubDivisionId: null | string
    TeamId: null | string
    StakeholderId: null | string
    PartnerTypeId: null | string
    PartnerNameId: null | string
    PartnerTeamId: null | string
    PartnerStakeholderId: null | string
    date: Date
    color: string
}

export function saveChangeCalendarData(items: ItemsToSave[], callback?: (error?: string) => void) {
    return async (dispatch: Dispatch<any>, getState: RootState) => {
        const data = [...getState().reports.changeCalendar.data]
        try {
            const requestData = items.map(({ date, color, ...rest }) => ({
                Date: toServerTime(date),
                Id: '00000000-0000-0000-0000-000000000000',
                Color: color,
                ...rest,
            }))

            const newData = [...getState().reports.changeCalendar.data]
            items.forEach((item) => {
                const index = findIndex(newData, (i: ChangeCalendarItem) => {
                    if (!isSameDay(i.Date, item.date)) {
                        return false
                    }
                    return objectEquals(i, item, [
                        'DivisionId',
                        'SubDivisionId',
                        'TeamId',
                        'StakeholderId',
                        'PartnerTypeId',
                        'PartnerNameId',
                        'PartnerTeamId',
                        'PartnerStakeholderId',
                    ])
                })
                console.log('index', index)
                const newItem = {
                    ...item,
                    Date: item.date,
                    Color: item.color,
                    CreatedAt: new Date(),
                    CreatedBy: '',
                    Id: '00000000-0000-0000-0000-000000000000',
                }
                if (index === -1) {
                    newData.push(newItem)
                } else {
                    newData[index] = newItem
                }
            })

            dispatch(slice.actions.setData([...newData]))

            await getResponseData('/api/CompanySettings/SaveCalendarReportDates', requestData)
            callback && callback()
        } catch (e) {
            dispatch(slice.actions.setError(getErrorMessage(e)))
            callback && callback(getErrorMessage(e))
            dispatch(slice.actions.setData([...data]))
        }
    }
}
