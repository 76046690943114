import SvgIconStyle from 'components/SvgIconStyle'
import React from 'react'
import { isString } from 'lodash'

export function getTypeImage() {
    const images = [1, 2, 3, 4, 5, 6]
    const image = images[Math.floor(Math.random() * images.length)]

    return '/static/initiatives/initiativeTypes/' + image + '.svg'
}

export const iconSize = 22

export const getIcon = (name: string, _iconSize: number = iconSize) => (
    <SvgIconStyle
        src={name.includes('/') ? name : `/static/icons/navbar/${name}.svg`}
        sx={{ width: _iconSize, height: _iconSize }}
    />
)

export const splitImageVerticallyByMaxHeight = async (
    image: HTMLImageElement | string,
    width: number,
    height: number,
    maxHeight: number
): Promise<string[]> => {
    let imageElem = image as HTMLImageElement
    if (isString(image)) {
        imageElem = new Image()
        imageElem.src = image
        await imageElem.decode()
    } else {
        imageElem = image as HTMLImageElement
        await imageElem.decode()
    }
    imageElem.style.position = 'absolute'
    imageElem.style.left = -(width * 2) + 'px'
    let partStartY = 0
    const partImages: string[] = []
    while (partStartY < height) {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        canvas.setAttribute('height', '' + maxHeight)
        canvas.setAttribute('width', '' + width)
        context?.drawImage(imageElem, 0, partStartY, width, maxHeight, 0, 0, width, maxHeight)
        partImages.push(canvas.toDataURL('image/png', 1.0))
        partStartY += maxHeight
    }
    return partImages
}
