import { clamp, get, sortBy } from 'lodash'

const GuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i

export const isGuid = (value: string) => GuidRegex.test(value)

export const fitToColumn = (arrayOfArray: string[][]) =>
    arrayOfArray[0].map((_a, i) => ({ wch: Math.max(...arrayOfArray.map((a2) => (a2[i] ? a2[i].toString().length + 5 : 40))) }))

export const generateUUID = () => {
    let d = new Date().getTime() //Timestamp
    let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0 //Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16 //random number between 0 and 16
        if (d > 0) {
            //Use timestamp until depleted
            r = (d + r) % 16 | 0
            d = Math.floor(d / 16)
        } else {
            //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0
            d2 = Math.floor(d2 / 16)
        }
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
}

export const stringFloatToFixed = (number: string, floatMax: number) => {
    const dotPos = number.indexOf('.')

    if (dotPos >= 0) {
        const toRemove = floatMax <= 0 ? dotPos : dotPos + 1 + floatMax
        return number.slice(0, toRemove)
    }

    return number
}

/** transforms input string into string + number combo
 * floatMax is the amount of numbers after dot */
export const string = (
    newString: string,
    previousString: string,
    previousNumber: number,
    min = 0,
    max = Number.MAX_SAFE_INTEGER,
    floatMax = 2
): [newString: string, newNumber: number] => {
    const stringNumber = stringFloatToFixed(newString.trim(), floatMax)
    // empty string replaced with min(zero)
    if (stringNumber.length === 0) return [min.toString(), min]

    // parsing
    const number = parseFloat(stringNumber)

    // nan = return previous values
    if (isNaN(number)) return [previousString, previousNumber]

    // in min-max range
    const clampedNumber = clamp(number, min, max)

    // allowing only one zero at start
    if (Number(stringNumber) === clampedNumber) return [stringNumber.replace(/^0+(?=0\.|[0-9])/, ''), clampedNumber]

    return [clampedNumber.toString(), clampedNumber]
}

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

export const sortByCaseSensitive = <T>(collection: Array<T> | null | undefined, iteratee: string | null = null): T[] =>
    sortBy(collection || [], (o) => {
        if (!iteratee) {
            return ('' + o)?.toLowerCase() || (o as string)
        }
        const val = get(o, iteratee, iteratee) as string
        return (val || '')?.toLowerCase() || val
    })

export const includesString = (inStr: string | undefined | null, searchStr: string | null | undefined) =>
    (inStr || '').toLowerCase().includes((searchStr || '').toLowerCase())

export const sortByPriority = <T>(collection: any): T[] =>
    collection.sort((a: any, b: any) => {
        const keyA = isNaN(a.key) ? a.key : parseInt(a.key, 10)
        const keyB = isNaN(b.key) ? b.key : parseInt(b.key, 10)

        // If both keys are numeric
        if (!isNaN(keyA) && !isNaN(keyB)) {
            return keyA - keyB
        }

        // If keyA is numeric and keyB is not, keyA comes first
        if (!isNaN(keyA) && isNaN(keyB)) {
            return -1
        }

        // If keyA is not numeric and keyB is, keyB comes first
        if (isNaN(keyA) && !isNaN(keyB)) {
            return 1
        }

        // If both keys are non-numeric, compare as strings
        if (keyA < keyB) return -1
        if (keyA > keyB) return 1
        return 0
    })
