import React, { useEffect, useMemo, useState } from 'react'
import { Box, Button, Divider, List, ListItem, ListItemText, Popover, TextField } from '@mui/material'
import { PickersDay, PickersDayProps, StaticDatePicker } from '@mui/x-date-pickers'
import { formatDate, isValidDate } from 'utils/formatDate'
import { styled } from '@mui/material/styles'
import {
    isWithinInterval,
    isSameDay,
    parseISO,
    addMonths,
    addYears,
    startOfDay,
    startOfMonth,
    endOfMonth,
    endOfDay,
    startOfQuarter,
    endOfQuarter,
} from 'date-fns'
import { isString } from 'lodash'
import { SxProps } from '@mui/system'
import { InputProps } from '@mui/material/Input'
import CloseIcon from '@mui/icons-material/Close'

export type DateRange<TDate> = [TDate | null, TDate | null]

export interface PreselectedDate {
    title: string
    range: [Date, Date]
}

interface DateRangePickerProps {
    dateRange: DateRange<any>
    onChange: (dateRange: DateRange<any>) => void
    startText?: string
    endText?: string
    showLabel?: boolean
    defaultRange?: DateRange<any>
    allowEmptyRange?: boolean
    predefinedDates?: PreselectedDate[]
    sxBox?: SxProps
    combined?: boolean
    reset?: boolean
    confirm?: boolean
    InputProps?: Partial<InputProps>
    minDate?: Date | null
    maxDate?: Date | null
    error?: boolean
    helperText?: any
    elementRef?: any
}

type CustomPickerDayProps = PickersDayProps<Date> & {
    dayIsBetween: boolean
    isFirstDay: boolean
    isLastDay: boolean
}

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
    ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.dark,
        },
    }),
    ...(isFirstDay && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    }),
    ...(isLastDay && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    }),
})) as React.ComponentType<CustomPickerDayProps>

const preSelectDates = [
    {
        title: 'This Month',
        range: [startOfMonth(startOfDay(new Date())), endOfMonth(endOfDay(new Date()))],
    },
    {
        title: 'This and Next Month',
        range: [startOfMonth(startOfDay(new Date())), addMonths(endOfDay(new Date()), 1)],
    },
    {
        title: 'This Quarter',
        range: [startOfQuarter(startOfDay(new Date())), endOfQuarter(endOfDay(new Date()))],
    },
    {
        title: 'Half a year',
        range: [startOfMonth(startOfDay(new Date())), endOfMonth(addMonths(endOfDay(new Date()), 5))],
    },
    {
        title: 'Nine months',
        range: [startOfMonth(startOfDay(new Date())), endOfMonth(addMonths(endOfDay(new Date()), 8))],
    },
    {
        title: 'Year',
        range: [startOfMonth(startOfDay(new Date())), endOfMonth(addMonths(endOfDay(new Date()), 11))],
    },
] as PreselectedDate[]

const DateRangePicker = ({
    dateRange,
    onChange,
    allowEmptyRange,
    startText,
    endText,
    defaultRange,
    maxDate,
    minDate,
    predefinedDates = preSelectDates,
    combined = false,
    reset = false,
    confirm = false,
    sxBox = {},
    showLabel = true,
    InputProps = {},
    error,
    helperText,
    elementRef,
}: DateRangePickerProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
    const [internalDateRange, setInternalDateRange] = useState<DateRange<Date | null>>([null, null])

    useEffect(() => {
        if (!dateRange) {
            return
        }

        if (dateRange?.filter((i) => i).length === 0) {
            // assume [ null, null ]
            if (allowEmptyRange) {
                setInternalDateRange([null, null])
            }
            return
        }

        let start = dateRange[0] || null
        let end = dateRange[1] || null
        if (isString(start)) {
            start = parseISO(start)
        }
        if (isString(end)) {
            end = parseISO(end)
        }

        setInternalDateRange([isValidDate(start) ? start : null, isValidDate(end) ? end : null])
    }, [dateRange])

    useEffect(() => {
        if (elementRef === undefined) {
            return
        }
        setAnchorEl(elementRef)
    }, [elementRef])

    const inputOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget)
        for (const input of event.currentTarget.getElementsByTagName('input')) {
            input.blur()
        }
        window.focus()
        event.stopPropagation()
        return false
    }

    const InValidRange = useMemo(() => internalDateRange.filter((i) => i).length !== 2, [internalDateRange])

    const onDaySelect = (date: Date | null, preferedIndex: number) => {
        if (!date) return

        let [start, end] = internalDateRange
        if (!start) {
            setInternalDateRange([date, end])
            return
        }
        if (!end) {
            if (start.getTime() > date?.getTime()) {
                setInternalDateRange([date, start])
                return
            }
            setInternalDateRange([start, date])
            return
        }

        if (start && end) {
            if (preferedIndex === 0 && date) {
                start = date
                setInternalDateRange([date, end])
            } else if (date) {
                end = date
                setInternalDateRange([start, date])
            }
        }

        const dateTime = date.getTime()
        const startTime = start?.getTime() || 0
        const endTime = end?.getTime() || 0

        if (startTime > dateTime) {
            setInternalDateRange([date, start])
            return
        }

        if (endTime < dateTime) {
            setInternalDateRange([start, date])
            return
        }
        if (startTime < dateTime && endTime > dateTime) {
            setInternalDateRange([start, date])
            return
        }
    }

    ///(day: TDate, selectedDays: TDate[], pickersDayProps: PickersDayProps<TDate>)
    const renderWeekPickerDay = (date: Date, _selectedDates: Array<Date>, pickersDayProps: PickersDayProps<Date>) => {
        const start = internalDateRange[0] || null
        const end = internalDateRange[1] || null

        let dayIsBetween = false
        try {
            dayIsBetween = start && end && start?.getTime() !== end.getTime() ? isWithinInterval(date, { start, end }) : false
        } catch (e) {
            ///console.log({ start, end })
            console.log(e)
        }
        const isFirstDay = start && end ? isSameDay(date, start as Date) : false
        const isLastDay = end ? isSameDay(date, end as Date) : false

        return (
            <CustomPickersDay
                {...pickersDayProps}
                disableMargin
                dayIsBetween={dayIsBetween}
                isFirstDay={isFirstDay}
                isLastDay={isLastDay}
            />
        )
    }

    const onCloseDatePicker = () => {
        if (!confirm) {
            if (InValidRange) {
                if (allowEmptyRange) {
                    setInternalDateRange([null, null])
                    anchorEl !== null && onChange([null, null])
                } else {
                    setInternalDateRange(defaultRange ? defaultRange : [null, null])
                }
            } else {
                if (anchorEl !== null) {
                    onChange(internalDateRange)
                }
            }
        }
        setAnchorEl(null)
    }
    const renderInputElement = () => (
        <>
            {combined ? (
                <TextField
                    size={'small'}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label={showLabel ? `${startText ? startText : 'Start Date'} - ${endText ? endText : 'End Date'}` : undefined}
                    value={[formatDate(internalDateRange[0] || ''), formatDate(internalDateRange[1] || '')].filter((i) => i).join(' - ')}
                    onClick={inputOnClick}
                    error={error}
                    helperText={helperText}
                    InputProps={{ readOnly: true, ...InputProps }}
                />
            ) : (
                <>
                    <TextField
                        size={'small'}
                        fullWidth={true}
                        label={showLabel ? (startText ? startText : 'Start Date') : undefined}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={error}
                        helperText={helperText}
                        value={formatDate(internalDateRange[0])}
                        onClick={inputOnClick}
                        InputProps={{ readOnly: true, ...InputProps }}
                    />
                    <Box sx={{ mx: 0.5 }} />
                    <TextField
                        size='small'
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={error}
                        helperText={helperText}
                        label={showLabel ? (endText ? endText : 'End Date') : undefined}
                        value={formatDate(internalDateRange[1])}
                        onClick={inputOnClick}
                        InputProps={{ readOnly: true, ...InputProps }}
                    />
                </>
            )}
        </>
    )

    return (
        <Box
            display={'flex'}
            alignItems={'baseline'}
            sx={{ mt: '0px', ...sxBox }}
        >
            {elementRef === undefined && renderInputElement()}

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={onCloseDatePicker}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{
                    paddingBottom: 10,
                    zIndex: 99999999000,
                }}
            >
                <CloseIcon
                    width={20}
                    height={20}
                    onClick={onCloseDatePicker}
                    sx={{ cursor: 'pointer', m: '5px 5px', float: 'right' }}
                />
                <Box
                    display={'flex'}
                    alignItems={'baseline'}
                    sx={{
                        '& .PrivatePickersSlideTransition-root': {
                            minHeight: 230,
                        },
                    }}
                >
                    <StaticDatePicker
                        minDate={minDate ? minDate : addYears(new Date(), -10)}
                        maxDate={maxDate || undefined}
                        views={['year', 'month', 'day']}
                        displayStaticWrapperAs='desktop'
                        value={internalDateRange[0]}
                        disableHighlightToday={true}
                        onChange={(date: Date | null) => onDaySelect(date, 0)}
                        renderDay={renderWeekPickerDay}
                        renderInput={() => <></>}
                    />

                    <StaticDatePicker
                        views={['year', 'month', 'day']}
                        displayStaticWrapperAs='desktop'
                        value={internalDateRange[1] || null}
                        defaultCalendarMonth={
                            internalDateRange[1]
                                ? internalDateRange[1]?.getMonth() === internalDateRange[0]?.getMonth()
                                    ? addMonths(internalDateRange[1], 1)
                                    : internalDateRange[1]
                                : addMonths(new Date(), 1)
                        }
                        disableHighlightToday={true}
                        onChange={(date: Date | null) => onDaySelect(date, 1)}
                        minDate={internalDateRange[0] || minDate || undefined}
                        maxDate={maxDate ? maxDate : undefined}
                        renderDay={renderWeekPickerDay}
                        renderInput={() => <></>}
                    />
                    {predefinedDates?.length > 0 && (
                        <Box>
                            <List sx={{ maxWidth: 300 }}>
                                {predefinedDates.map((item, index) => (
                                    <div key={'devider2323232' + index}>
                                        <ListItem
                                            alignItems='flex-start'
                                            sx={{
                                                cursor: 'pointer',
                                                '&:hover': { background: 'rgb(247, 245, 245, 0.8)' },
                                                padding: '2px 16px',
                                            }}
                                        >
                                            <ListItemText
                                                sx={{ '&:hover': { background: 'rgb(247, 245, 245, 0.9)' } }}
                                                primary={item.title}
                                                primaryTypographyProps={{
                                                    sx: { fontSize: '14px !important' },
                                                }}
                                                secondary={
                                                    <>
                                                        {formatDate(item.range[0])} - {formatDate(item.range[1])}
                                                    </>
                                                }
                                                secondaryTypographyProps={{
                                                    sx: { fontSize: '10px !important' },
                                                }}
                                                onClick={() => setInternalDateRange([...item.range] as DateRange<Date>)}
                                            />
                                        </ListItem>
                                        <Divider
                                            key={'devider' + index}
                                            component='li'
                                        />
                                    </div>
                                ))}
                            </List>
                        </Box>
                    )}
                </Box>
                <Box
                    display={'flex'}
                    alignItems={'baseline'}
                    justifyContent={'flex-end'}
                    alignContent={'space-between'}
                    paddingRight={5}
                    paddingBottom={2}
                >
                    <Button
                        size={'small'}
                        color={'inherit'}
                        disabled={internalDateRange.filter((i) => i).length === 0}
                        onClick={() => {
                            setInternalDateRange([null, null])
                            allowEmptyRange && onChange([null, null])
                        }}
                    >
                        Clear
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        size={'small'}
                        color={'error'}
                        disabled={InValidRange}
                        onClick={() => {
                            onChange(internalDateRange)
                            if (reset) {
                                setInternalDateRange([null, null])
                            }
                            setAnchorEl(null)
                        }}
                    >
                        Apply
                    </Button>
                </Box>
            </Popover>
        </Box>
    )
}

export default DateRangePicker
