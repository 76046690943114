import { Theme } from '@mui/material/styles'
import { responsiveFontSizes } from 'utils/formatFontSize'

// ----------------------------------------------------------------------

export default function Menu(theme: Theme) {
    return {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: theme.palette.action.selected,
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                        },
                    },
                    ...responsiveFontSizes({ sm: 9, md: 9, lg: 12 }),
                },
            },
        },
    }
}
