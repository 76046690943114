import React from 'react'
import PropTypes from 'prop-types'

export default class ErrorHandler extends React.Component {
    static propTypes: { children: PropTypes.Validator<object> }
    constructor(props: Record<string, unknown>) {
        super(props)
        this.state = { error: null, errorInfo: null, hasError: false }
    }

    static getDerivedStateFromError(_error: unknown) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error: unknown, errorInfo: unknown) {
        console.log(error)
        try {
            // @ts-expect-error: Initialized in index
            window.Bugsnag?.notify(error)
        } catch (_err) {
            // console.log('notify bugsnag error', err)
        }
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
        })
    }

    renderError() {
        return <></>
    }

    render() {
        // @ts-expect-error: it has item
        if (this.state.hasError) {
            return this.renderError()
        } else {
            // @ts-expect-error: it has item
            return this.props.children
        }
    }
}

ErrorHandler.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
}
