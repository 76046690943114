import { startsWith } from 'lodash'

export const isProduction = () => startsWith(window.location.host, 'tool.')

export const isStaging = () =>
    startsWith(window.location.host, 'devui.') || startsWith(window.location.host, 'newtool.') || startsWith(window.location.host, 'local')

export const isCompassAutomator = () => isStaging() || startsWith(window.location.host, 'tool2.')

export const closerNumber = (target: number, num1: number, num2: number): number => {
    const diff1 = Math.abs(target - num1)
    const diff2 = Math.abs(target - num2)

    return diff1 < diff2 ? num1 : num2
}
