import { createSlice } from '@reduxjs/toolkit'
import { sortBy, uniqBy } from 'lodash'
import axiosInstance, { fetchResponseData, responseData } from 'utils/axios'
import { Dispatch } from 'redux'
import { PaymentPlan, StripeCardDetails, ViewerPaymentPlan } from '../../@types/payments'
import { reportError } from 'utils/errorReport'
import { RootState } from 'store'

export interface PaymentsStateProps {
    isLoading: boolean
    error: boolean
    paymentPlans: Array<PaymentPlan>
    viewerPlans: ViewerPaymentPlan[]
    cardDetails: StripeCardDetails

    PlanId: null | string
    UseFreeTrial: boolean
    CanUseTrial: boolean
    stripeEmail: null | string
    stripeToken: null | string
    stripeCouponCode: null | string
}

const initialState = {
    isLoading: false,
    error: false,
    cardDetails: {} as Partial<StripeCardDetails>,
    paymentPlans: [],
    planId: null,
    useFreeTrial: false,
    canUseTrial: false,
    stripeEmail: null,
    stripeToken: null,
    stripeCouponCode: null,
    viewerPlans: [],
}

const slice = createSlice({
    name: 'payments',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
            state.error = false
        },

        stopLoading(state) {
            state.isLoading = false
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        // GET PRODUCTS
        setPaymentPlans(state, action) {
            state.isLoading = false
            // @ts-expect-error: NEED better Typescript descr
            state.paymentPlans = sortBy<PaymentPlan>(uniqBy(action.payload.PaymentListsModel, 'CountUser'), ['CountUser'])
            state.planId = action.payload.PlanId
            state.useFreeTrial = action.payload.UseFreeTrial
            state.canUseTrial = action.payload.CanUseTrial
            state.stripeEmail = action.payload.stripeEmail
            state.stripeToken = action.payload.stripeToken
            state.stripeCouponCode = action.payload.stripeCouponCode
        },

        setViewerPlans(state, action) {
            state.viewerPlans = action.payload
        },

        setStripeCardDetails(state, action) {
            state.cardDetails = action.payload
        },
    },
})

export default slice.reducer

export function getPaymentPlans() {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(slice.actions.startLoading())
        try {
            const resp = await axiosInstance.post(
                '/api/Payment/GetSelectPlan',
                {},
                {
                    params: {
                        userEmail: getState()?.authJwt?.user?.email || '',
                    },
                }
            )
            const data = responseData(resp.data)
            dispatch(slice.actions.setPaymentPlans({ ...data }))
        } catch (error) {
            reportError(error)
            dispatch(slice.actions.hasError(error))
        } finally {
            dispatch(slice.actions.stopLoading())
        }
    }
}

export function getViewerPaymentPlans() {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(slice.actions.startLoading())
        try {
            const resp = await axiosInstance.get('/api/Payment/GetPlanViewerInterval', {
                params: {
                    userEmail: getState()?.authJwt?.user?.email || '',
                },
            })
            const data = responseData(resp.data)
            dispatch(slice.actions.setViewerPlans([...data]))
        } catch (error) {
            reportError(error)
            dispatch(slice.actions.hasError(error))
        } finally {
            dispatch(slice.actions.stopLoading())
        }
    }
}

export function getCardDetails(stripeToken: string) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const resp = await fetchResponseData('/api/Payment/GetCardDetails', { params: { token: stripeToken } })
            // @ts-expect-error: NEED better Typescript descr
            dispatch(slice.actions.setStripeCardDetails({ ...(resp?.Result || {}) }))
        } catch (error) {
            reportError(error)
            dispatch(slice.actions.hasError(error))
        } finally {
            dispatch(slice.actions.stopLoading())
        }
    }
}
