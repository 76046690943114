import React from 'react'
import { Navigate } from 'react-router-dom'
// hooks
import useAuth from 'hooks/useAuth'
// routes
import { PATH_DASHBOARD, PATH_SUPERADMIN } from 'routes/paths'
import SkeletonAuthenticatedApp from 'components/SkeletonLoaders/SkeletonAuthenticatedApp'

// ----------------------------------------------------------------------

type GuestProtectProps = {
    children: React.ReactNode
}

export default function GuestProtect({ children }: GuestProtectProps) {
    const { isLoading, isAuthenticated, isSuperAdmin } = useAuth()

    if (isLoading) {
        return <SkeletonAuthenticatedApp />
    }

    if (isAuthenticated) {
        return <Navigate to={isSuperAdmin ? PATH_SUPERADMIN.manage.dashboard : PATH_DASHBOARD.root} />
    }

    return <>{children}</>
}
