import React from 'react'

import { styled } from '@mui/material/styles'
import { Box, CardHeader, CardHeaderProps } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import MButton from 'components/@material-extend/MButton'
import typography from 'theme/typography'
import { get } from 'lodash'

const CardHeaderUnderlineStyled = styled(CardHeader)(() => ({
    padding: '10px 10px 10px 10px',
    borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
}))

const CardHeaderUnderline = (props: CardHeaderProps) => {
    const { title } = props
    return (
        <CardHeaderUnderlineStyled
            {...props}
            title={<span>{title}</span>}
        />
    )
}

export default CardHeaderUnderline

interface Props extends CardHeaderProps {
    addAction: VoidFunction
    variant?: string
}

interface CardHeaderActionsProps extends CardHeaderProps {
    variant?: string
}

export const CardHeaderActions = ({ title, children, variant, ...rest }: CardHeaderActionsProps) => {
    const fonts = variant ? get(typography, variant as string, {}) : {}
    return (
        <CardHeaderUnderline
            {...rest}
            title={
                <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Box sx={{ ...fonts }}>{title}</Box>
                    <Box>{children}</Box>
                </Box>
            }
        />
    )
}

export const CardHeaderNewButton = ({ addAction, title, ...rest }: Props) => (
    <CardHeaderUnderline
        {...rest}
        title={
            <>
                {title}
                <Box
                    sx={{
                        mt: { xs: 2, sm: 0 },
                        position: { sm: 'absolute' },
                        top: { sm: 8 },
                        right: { sm: 24 },
                    }}
                >
                    <MButton
                        variant='contained'
                        color='error'
                        size='small'
                        onClick={addAction}
                    >
                        Add {<AddIcon fontSize='small' />}
                    </MButton>
                </Box>
            </>
        }
    />
)
