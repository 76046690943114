import React, { forwardRef } from 'react'
// material
import { useTheme } from '@mui/material/styles'
import { Fab, FabProps } from '@mui/material'
//

// ----------------------------------------------------------------------

interface MFabProps extends Omit<FabProps, 'color'> {
    color?: 'inherit' | 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error'
}

const MFab = forwardRef<HTMLButtonElement, MFabProps>(({ color = 'primary', children, sx, ...other }, ref) => {
    const theme = useTheme()

    if (color === 'default' || color === 'inherit' || color === 'primary' || color === 'secondary') {
        return (
            <Fab
                ref={ref}
                color={color}
                sx={sx}
                {...other}
            >
                {children}
            </Fab>
        )
    }

    return (
        <Fab
            ref={ref}
            sx={{
                boxShadow: theme.customShadows[color],
                color: theme.palette[color].contrastText,
                bgcolor: theme.palette[color].main,
                '&:hover': {
                    bgcolor: theme.palette[color].dark,
                },
                ...sx,
            }}
            {...other}
        >
            {children}
        </Fab>
    )
})
MFab.displayName = 'MFab'

export default MFab
