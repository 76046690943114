import axios, { isCancel } from 'axios'
import { Dispatch } from 'redux'
import { RootState } from 'store/store'
import axiosInstance, { responseTrasnform } from 'utils/axios'
import { createSelector } from 'reselect'
import { createSlice } from '@reduxjs/toolkit'
import { AIInsightData, SettingsAIInsightItemDetails } from '../../@types/insights'
import { formatISO } from 'date-fns'

export interface InsightsStateProps {
    isLoading: boolean
    initiativeAIData: AIInsightData[]
    settingsAIData: SettingsAIInsightItemDetails[]
}

const initialState: InsightsStateProps = {
    isLoading: false,
    initiativeAIData: [],
    settingsAIData: [],
}

const slice = createSlice({
    name: 'insights',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
        },
        stopLoading(state) {
            state.isLoading = false
        },
        setInitiativeAIData(state, action) {
            state.initiativeAIData = action.payload
        },
        setSettingsAIData(state, action) {
            state.settingsAIData = action.payload
        },
    },
})

export default slice.reducer

let insightsDataAxiosSource = axios.CancelToken.source()
let insightsDataAxiosSource2 = axios.CancelToken.source()

export function fetchInsightsData(startDate: Date | null, endDate: Date | null) {
    return async (dispatch: Dispatch<any>) => {
        insightsDataAxiosSource.cancel()
        insightsDataAxiosSource2.cancel()
        insightsDataAxiosSource = axios.CancelToken.source()
        insightsDataAxiosSource2 = axios.CancelToken.source()
        dispatch(slice.actions.startLoading())

        const toServerTime = (date: Date | null) => formatISO(date as Date, { representation: 'date' }) + 'T00:00:00'

        Promise.allSettled([
            axiosInstance
                .post(
                    '/api/insights/auto-generate/initiative-insights',
                    {
                        startDate: toServerTime(startDate),
                        endDate: toServerTime(endDate),
                    },
                    {
                        cancelToken: insightsDataAxiosSource.token,
                        transformResponse: responseTrasnform,
                    }
                )
                .then(({ data }) => {
                    dispatch(slice.actions.setInitiativeAIData(data as AIInsightData[]))
                })
                .catch((err) => {
                    if (isCancel(err)) {
                        return
                    }
                    reportError(err)
                }),
            axiosInstance
                .get('/api/insights/auto-generate/setting-insights', {
                    cancelToken: insightsDataAxiosSource2.token,
                    transformResponse: responseTrasnform,
                })
                .then(({ data }) => {
                    dispatch(slice.actions.setSettingsAIData(data as AIInsightData[]))
                })
                .catch((err) => {
                    if (isCancel(err)) {
                        return
                    }
                    reportError(err)
                }),
        ]).finally(() => {
            dispatch(slice.actions.stopLoading())
        })
    }
}

export const getAIInitativeInsightsData = createSelector(
    (state: RootState) => state,
    (state): AIInsightData[] => state.insights.initiativeAIData
)

export const getAISettingsInsightsData = createSelector(
    (state: RootState) => state,
    (state): SettingsAIInsightItemDetails[] => state.insights.settingsAIData
)

export const isLoadingInsightsData = createSelector(
    (state: RootState) => state,
    (state): boolean => state.insights.isLoading
)
