import { find, isArray, isEmpty, sortBy, uniqBy } from 'lodash'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { getTypeImage } from 'utils/images'

// utils
import axiosInstance, { fetchResponseData, getResponseData, getSandboxInstance, responseData } from 'utils/axios'

import {
    BenefitCategories,
    BenefitList,
    BenefitTypes,
    BusinessPosition,
    ImpactList,
    Initiative,
    InitiativeDeliverable,
    InitiativeList,
    InitiativePhase,
    InitiativeTypes,
    InspectHistory,
    MappingRecord,
    ScenarioItem,
    ThemeList,
} from '../../@types/initiative'
import { Dispatch } from 'react'
import axios, { CancelTokenSource } from 'axios'
import { reportError } from 'utils/errorReport'
import { GeographyHierarchyTree, GeographyHierarchyTreeResponse, GeographyItem, GeographyItemResponse } from '../../@types/impact'
import { createSelector } from 'reselect'
import { sortByCaseSensitive } from 'utils/string'
import { ChangeResourceList, UserListItem } from '../../@types/user'
import { User } from '../../@types/account'
import { parseISO } from 'date-fns'
import { isSandBox } from 'store/slices/settings'

type FilteredInitiativeList = {
    data: InitiativeList[]
    Page: number
    PageNumber: number
    Total: number
}

type InitiativeState = {
    isLoading: boolean
    isInitiativesLoading: boolean
    isInitiativesDropDownLoading: boolean
    isLoadingImpacts: boolean
    isBenefitLoading: boolean
    isAddingTag: boolean
    error: boolean
    initiativeList: InitiativeList[]
    filteredinitiativeList: {
        data: InitiativeList[]
        Page: number
        PageNumber: number
        Total: number
    }
    inspectHistory: InspectHistory[]
    initiativeTypesList: InitiativeTypes[]
    impactList: ImpactList[]
    changedImpacts: string[]
    benefitList: BenefitList[]
    initiative: Initiative[]
    initiativeinfo: Initiative
    owners: string[]
    contacts: string[]
    initiativeDivisionThemesList: ThemeList[]
    initiativeStrategicAlignments: any[]
    initiativePhases: InitiativePhase[]
    userList: UserListItem[]
    changeResourceList: ChangeResourceList[]
    tagList: any[]
    geographyHierarchyTree: GeographyHierarchyTree
    sandboxGeographyHierarchyTree: GeographyHierarchyTree
    benefitTypes: BenefitTypes[]
    benefitCategories: BenefitCategories[]
    benefitHealth: any[]
    scenarios: ScenarioItem[]
    initiativeDivisionThemesMappingRecords: MappingRecord[]
    deliverables: InitiativeDeliverable[]
    businessPositions: BusinessPosition[]
    isAddingBusinessPositions: boolean
}

const initialState: InitiativeState = {
    isLoading: false,
    isInitiativesLoading: false,
    isInitiativesDropDownLoading: false,
    isAddingTag: false,
    isLoadingImpacts: false,
    isBenefitLoading: false,
    error: false,
    initiativeList: [],
    filteredinitiativeList: {
        data: [],
        Page: 0,
        PageNumber: 0,
        Total: 0,
    },
    inspectHistory: [],
    initiativeTypesList: [],
    impactList: [],
    changedImpacts: [],
    benefitList: [],
    initiative: [],
    initiativeinfo: {} as Initiative,
    owners: [],
    contacts: [],
    initiativeDivisionThemesList: [],
    initiativeStrategicAlignments: [],
    initiativePhases: [],
    userList: [],
    tagList: [],
    geographyHierarchyTree: {
        Region: [],
        DistrictOffice: [],
        NetworkOffice: [],
    },
    sandboxGeographyHierarchyTree: {
        Region: [],
        DistrictOffice: [],
        NetworkOffice: [],
    },
    benefitTypes: [],
    benefitCategories: [],
    benefitHealth: [],
    scenarios: [],
    initiativeDivisionThemesMappingRecords: [],
    deliverables: [],
    businessPositions: [],
    changeResourceList: [],
    isAddingBusinessPositions: false,
}

const slice = createSlice({
    name: 'initiative',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
        },
        stopLoading(state) {
            state.isLoading = true
        },
        // isInitiativesDropDownLoading

        startInitiativesDropDownLoading(state) {
            state.isInitiativesDropDownLoading = true
        },

        stopInitiativesDropDownLoading(state) {
            state.isInitiativesDropDownLoading = false
        },

        startInitiativesLoading(state) {
            state.isInitiativesLoading = true
        },
        stopInitiativesLoading(state) {
            state.isInitiativesLoading = false
        },
        startAddingTag(state) {
            state.isAddingTag = true
        },
        stopAddingTag(state) {
            state.isAddingTag = false
        },
        startImpactsLoading(state) {
            state.isLoadingImpacts = true
        },
        startBenefitLoading(state) {
            state.isBenefitLoading = true
        },
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },
        loaded(state) {
            state.isLoading = false
        },
        impactsLoaded(state) {
            state.isLoadingImpacts = false
        },
        benefitsLoaded(state) {
            state.isBenefitLoading = false
        },
        getInitiativeSuccess(state, action) {
            state.isLoading = false
            state.initiativeList = action.payload || []
        },
        getFilteredInitiativeSuccess(state, action) {
            state.isLoading = false
            state.filteredinitiativeList = {
                ...(action.payload || {}),
                data: action.payload?.Records || [],
                Total: action.payload.RecordsTotal,
            }
        },
        getInitiaiveByIdSuccess(state, action) {
            state.isLoading = false
            state.initiativeinfo = action.payload
        },
        getHistorySuccess(state, action) {
            state.isLoading = false
            state.inspectHistory = action.payload
        },

        getInitiativeTypesListSuccess(state, action) {
            state.isLoading = false
            state.initiativeTypesList = action.payload
        },
        getImpactSuccess(state, action) {
            state.isLoadingImpacts = false
            state.impactList = action.payload
        },
        getBenefitSuccess(state, action) {
            state.isBenefitLoading = false
            state.benefitList = action.payload
        },
        AddInitiativeSuccess(state, action) {
            state.isLoading = false
            state.initiative = action.payload
        },

        setOwners(state, action) {
            state.isLoading = false
            state.owners = action.payload
        },

        setContacts(state, action) {
            state.isLoading = false
            state.contacts = action.payload
        },
        setInitiativeDivisionThemesList(state, action) {
            state.isLoading = false
            state.initiativeDivisionThemesList = action.payload
        },
        setInitiativeDivisionThemesMappingRecords(state, action) {
            state.isLoading = false
            state.initiativeDivisionThemesMappingRecords = action.payload
        }, // getInitiativeDivisionThemesMappingRecords
        setInitiativeStrategicAlignments(state, action) {
            state.isLoading = false
            state.initiativeStrategicAlignments = action.payload
        },
        setInitiativePhases(state, action) {
            state.isLoading = false
            state.initiativePhases = action.payload
        },
        setUserList(state, action) {
            state.isLoading = false
            state.userList = action.payload
        },
        setCompanyChangeResourceList(state, action) {
            state.isLoading = false
            state.changeResourceList = action.payload
        },
        setTagList(state, action) {
            state.isLoading = false
            state.tagList = action.payload
        },
        setImpactGeographyTree(state, action) {
            state.isLoading = false
            state.geographyHierarchyTree = action.payload
        },
        setSandboxGeographyHierarchyTree(state, action) {
            state.isLoading = false
            state.sandboxGeographyHierarchyTree = action.payload
        },
        setBenefitType(state, action) {
            state.isLoading = false
            state.benefitTypes = sortByCaseSensitive(action.payload, 'Name') //
        },
        setBenefitCategories(state, action) {
            state.isLoading = false
            state.benefitCategories = action.payload
        },
        setBenefitHealth(state, action) {
            state.isLoading = false
            state.benefitHealth = action.payload
        },
        setScenarios(state, action) {
            state.scenarios = action.payload
        },

        setChangedImpacts(state, action) {
            state.changedImpacts = action.payload
        },

        setDeliverables(state, action) {
            state.deliverables = action.payload
        },

        setBusinessPositions(state, action) {
            state.businessPositions = action.payload
        },

        idAddingBusinessPosition(state, action) {
            state.isAddingBusinessPositions = action.payload
        },
        //
    },
})

// Reducer
export default slice.reducer

export const getImpacts = createSelector(
    (state: RootState) => state,
    (state): ImpactList[] => state.initiative.impactList
)

export const getInitiativeTypesList = createSelector(
    (state: RootState) => state,
    (state): InitiativeTypes[] => state.initiative.initiativeTypesList
)

// ----------------------------------------------------------------------

export function getInitiativeList(obj: Record<string, any> = {}) {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const isInitiativesDropDownLoading = getState().initiative.isInitiativesDropDownLoading
        if (isInitiativesDropDownLoading) {
            return
        }
        dispatch(slice.actions.getInitiativeSuccess([]))
        dispatch(slice.actions.startInitiativesDropDownLoading())
        try {
            const response: any = await getResponseData('/api/Initiative/GetInitiativesListForDropdown?request=d', {
                ...defaultFilterInitiativeListOptions,
                ...obj,
            })
            dispatch(slice.actions.getInitiativeSuccess(response))
        } catch (error) {
            reportError(error)
            dispatch(slice.actions.hasError(error))
        }
        dispatch(slice.actions.stopInitiativesDropDownLoading())
    }
}

export const defaultFilterInitiativeListOptions = {
    search: '',
    page: 0,
    myInitiatives: false,
    order: 'LastModifiedAt',
    sortDirection: 1,
    Length: 100000,
    EditableFieldValue: [],
    SelectedOrganizationItemId: [],
    SelectedPartnerGeographiesItemId: [],
    SelectedHierarchyItemId: [],
    StakeholderId: [],
    GeographyId: [],
    ActivityId: [],
    StrategicAlignmentsId: [],
    DivisionThemesId: [],
    PhaseId: [],
    DateFrom: '',
    DateTo: '',
    Date: '',
    TagIds: [],
    Owner: [],
    Contact: [],
    InititiveName: [],
    DivisionForStrategy: [],
    TypeIds: [],
    ImpactType: '',
    PartnerNameIds: [],
    PartnerTypeIds: [],
    PartnerStakeholderIds: [],
    RegionIds: [],
    DistrictOfficeIds: [],
    NetworkOfficeIds: [],
    PartnerOrgNameIds: [],
    PartnerOrgTeamIds: [],
    PartnerOrgTypeIds: [],
    PartnerScaleItemId: [],
}

export const fetchFilterInitiativesList = async (obj: any, source: CancelTokenSource | null) =>
    getResponseData('/api/Initiative/GetFilterInitiativesList?request=d', isEmpty(obj) ? defaultFilterInitiativeListOptions : obj, {
        cancelToken: source?.token,
    })

export const getFilteredInitiativeList = createSelector(
    (state: RootState) => state.initiative.filteredinitiativeList,
    (filteredinitiativeList): FilteredInitiativeList => ({
        data: filteredinitiativeList?.Records || [],
        Page: (filteredinitiativeList?.Page || 0) + 1,
        PageNumber: filteredinitiativeList.PageNumber || 1,
        Total: filteredinitiativeList.RecordsTotal || 0,
    })
)

export function getFilterInitiativeList(obj: any, source: CancelTokenSource | null) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.getFilteredInitiativeSuccess({}))
        dispatch(slice.actions.startInitiativesLoading())
        try {
            const response: any = await fetchFilterInitiativesList(obj, source)
            dispatch(slice.actions.getFilteredInitiativeSuccess(response || {}))
            dispatch(slice.actions.stopInitiativesLoading())
        } catch (error) {
            if (axios.isCancel(error)) return
            reportError(error)
            dispatch(slice.actions.hasError(error))
            dispatch(slice.actions.stopInitiativesLoading())
        }
    }
}

/// POST

export function getInitiative(id: string) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const response: any = await getResponseData('/api/Initiative/GetInitiativeById?request=d&id=' + id, {})
            dispatch(slice.actions.getInitiaiveByIdSuccess(response))
        } catch (error) {
            reportError(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function replaceTimingImpact(impact: ImpactList, From: Date, To: Date) {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(
            slice.actions.getImpactSuccess(
                getState().initiative.impactList.map((i: ImpactList) => {
                    if (i.Id !== impact.Id) {
                        return i
                    }

                    return {
                        ...i,
                        From,
                        To,
                        Timing: impact.Timing,
                    }
                })
            )
        )
    }
}

export function getInspectHistory(id: string) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const response: any = await getResponseData('/api/Initiative/GetLastUpdateHistoriesByInitiativeId?request=d&id=' + id, {})
            dispatch(slice.actions.getHistorySuccess(response))
        } catch (error) {
            reportError(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function getInitiativeFilteredList(obj: any) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const response: any = await getResponseData('/api/Initiative/GetFilterInitiativesList?request=d', obj)
            dispatch(slice.actions.getFilteredInitiativeSuccess(response?.Records || []))
        } catch (error) {
            reportError(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}

export const getInitiativeTypeList = createAsyncThunk('thunk/fetchImpactScales', async (obj: Record<string, any>, { dispatch }) => {
    dispatch(slice.actions.startLoading())
    try {
        const response: any = await getResponseData('/api/ChangeType/GetChangeTypeList?request=d', obj)
        const res = [
            ...(response || []).map((types: Record<string, any>) => {
                const { image = getTypeImage(), ...rest } = types
                return {
                    ...rest,
                    image,
                }
            }),
        ]
        dispatch(slice.actions.getInitiativeTypesListSuccess(res))
        return res
    } catch (error) {
        reportError(error)
        dispatch(slice.actions.hasError(error))
    }
    return []
})

export function addInitiative(obj: any) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const response: any = await getResponseData('/api/Initiative/SaveInitiatives?request=d', obj)
            dispatch(slice.actions.AddInitiativeSuccess(response))
        } catch (error) {
            reportError(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}

export const getChangeImpactByInitiativeId = async (obj: any, initiativeId: any): Promise<ImpactList[]> =>
    new Promise((resolve, reject) => {
        getResponseData('/api/Initiative/GetChangeImpactByInitiativeId?request=d&id=' + initiativeId, obj)
            .then((response) => {
                if (isEmpty(response) || !isArray(response)) {
                    resolve([])
                }
                resolve(
                    (response as Record<string, any>).map((impact: Record<string, any>) => ({
                        ...impact,
                        From: parseISO(impact.From as string),
                        To: parseISO(impact.To as string),
                    }))
                )
            })
            .catch((error) => reject(error))
    })

export function getImpactList(obj: any, initiativeId: any) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startImpactsLoading())
        try {
            const response = await getChangeImpactByInitiativeId(obj, initiativeId)
            dispatch(slice.actions.getImpactSuccess(response))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
            reportError(error)
        } finally {
            dispatch(slice.actions.impactsLoaded())
        }
    }
}

export function getBenefitList(obj: any, initiativeId: any) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startBenefitLoading())
        try {
            const response: any = await getResponseData('/api/Initiative/GetBenefitsByInitiativeId?request=d&id=' + initiativeId, obj)
            dispatch(slice.actions.getBenefitSuccess(response))
            dispatch(slice.actions.benefitsLoaded())
            return response
        } catch (error) {
            dispatch(slice.actions.hasError(error))
            reportError(error)
            dispatch(slice.actions.benefitsLoaded())
            return []
        }
    }
}

// ----------------------------------------------------------------------
export function getOwnersList() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const response: any = await fetchResponseData('/api/Dropdown/GetInitiativeOwner?request=d')
            dispatch(slice.actions.setOwners([...response]))
            return response
        } catch (error) {
            dispatch(slice.actions.hasError(error))
            reportError(error)
        }
    }
}

export function getContactsList() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const response: any = await fetchResponseData('/api/Dropdown/GetInitiativeContact?request=d')
            dispatch(slice.actions.setContacts(response))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
            reportError(error)
        }
    }
}

export function getInitiativeDivisionThemesList() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const response: any = await getResponseData('/api/InitiativeDivisionThemes/GetInitiativeDivisionThemesList?request=d', {})
            dispatch(slice.actions.setInitiativeDivisionThemesList(response))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
            reportError(error)
        }
    }
}

export function getInitiativeDivisionThemesMappingRecords() {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(slice.actions.startLoading())
        try {
            const requestData = {
                params: {
                    request: 'd',
                    themeId: 'null',
                    divisionId: 'null',
                    companyId: getState().company.settings.Id,
                },
            }
            const response: any = await fetchResponseData('/api/InitiativeDivisionThemes/GetAllMappingRecord', requestData)
            dispatch(slice.actions.setInitiativeDivisionThemesMappingRecords(response))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
            reportError(error)
        }
    }
}

export const getDivisionStrategyThemeMap = createSelector(
    (state: RootState): ThemeList[] => state.initiative.initiativeDivisionThemesList,
    (state: RootState): MappingRecord[] => state.initiative.initiativeDivisionThemesMappingRecords,
    (initiativeDivisionThemesList, initiativeDivisionThemesMappingRecords): Record<string, ThemeList[]> => {
        let res = {} as Record<string, ThemeList[]>

        ;(initiativeDivisionThemesMappingRecords || []).forEach((mapping: MappingRecord) => {
            if (!Object.prototype.hasOwnProperty.call(res, mapping.DivisionId)) {
                res = { ...res, [mapping.DivisionId]: [] }
            }
            ;(initiativeDivisionThemesList || []).filter((i: ThemeList) => i.Id === mapping.ThemeId).forEach((i: ThemeList) => res[mapping.DivisionId].push(i))
        })
        return res
    }
)

export function getInitiativeStrategicAlignments() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const response: any = await getResponseData('/api/InitiativeStrategicAlignments/GetInitiativeStrategicAliList?request=d', {})
            dispatch(slice.actions.setInitiativeStrategicAlignments(response))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
            reportError(error)
        }
    }
}

export const getInitiativePhases = createAsyncThunk('thunk/getInitiativePhases', async (_, { dispatch }) => {
    dispatch(slice.actions.startLoading())
    try {
        const response: any = await getResponseData('/api/InitiativePhases/GetInitiativePhasesList?request=d', {})
        const res = sortBy(uniqBy(response, 'Id'), (i: InitiativePhase) => i.DisplayOrder)
        dispatch(slice.actions.setInitiativePhases(res))
        return res
    } catch (error) {
        dispatch(slice.actions.hasError(error))
        reportError(error)
    }
    return []
})

export function getGetUserList() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const response: any = await getResponseData('/api/User/GetUserList?request=d', {})
            dispatch(slice.actions.setUserList(uniqBy(response, 'Id')))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
            reportError(error)
        }
    }
}

export const getResourceNames = createSelector(
    (state: RootState): InitiativeState => state.initiative,
    (initiative): ChangeResourceList[] => initiative.changeResourceList
)

export function getGetResourceNamesList() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const response: any = await fetchResponseData('/api/Initiative/GetCompanyChangeResources?request=d', {})
            dispatch(slice.actions.setCompanyChangeResourceList(uniqBy(response, 'NameOfUser')))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
            reportError(error)
        }
    }
}

export function addTag(tagname: string, onSuccess: (val: Record<string, any>) => void) {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(slice.actions.startAddingTag())
        try {
            const resp = (await getResponseData('/api/Tag/SaveTag?request=d', {
                Id: '00000000-0000-0000-0000-000000000000',
                Name: tagname,
            })) as Record<string, any>
            const tagList = getState()?.initiative?.tagList || []
            dispatch(slice.actions.setTagList([...tagList, resp]))
            onSuccess(resp)
        } catch (error) {
            dispatch(slice.actions.hasError(error))
            reportError(error)
        } finally {
            dispatch(slice.actions.stopAddingTag())
        }
    }
}

export function getTagList() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const response: any = await fetchResponseData('/api/Tag/GetTagList?request=d', {})
            dispatch(slice.actions.setTagList(response))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
            reportError(error)
        }
    }
}

export function dummy() {
    return async () => 'fake data'
}

export const getImpactGeographyTree = createSelector(
    (state: RootState): GeographyHierarchyTree => state.initiative.geographyHierarchyTree,
    (state: RootState): GeographyHierarchyTree => state.initiative.sandboxGeographyHierarchyTree,
    (state: RootState): User => state.authJwt.user,
    (state: RootState) => isSandBox(state),
    (geographyHierarchyTree, sandboxGeographyHierarchyTree, user, sandbox): GeographyHierarchyTree => {
        const tree = sandbox ? sandboxGeographyHierarchyTree : geographyHierarchyTree
        // sandbox
        if (user?.userRole === 'Company Admin') {
            return tree
        }

        return {
            Region: tree.Region.filter((geo) => !geo.IsHidden),
            DistrictOffice: tree.DistrictOffice.filter((geo) => !geo.IsHidden),
            NetworkOffice: tree.NetworkOffice.filter((geo) => !geo.IsHidden),
        }
    }
)

const geographyItemPrepareMetaData = (geo: GeographyItemResponse): GeographyItem => {
    try {
        return { ...geo, MetaData: JSON.parse(geo?.MetaData || '{}') }
    } catch (_err) {
        return { ...geo, MetaData: {} }
    }
}

export const getImpactGeographyList = createAsyncThunk('thunk/getImpactGeographyList', async (_, { dispatch, getState }) => {
    dispatch(slice.actions.startLoading())
    try {
        const isSandbox = isSandBox(getState())

        const response = await (isSandBox(getState()) ? await getSandboxInstance() : axiosInstance).post(
            '/api/ImpactGeography/GetImpactGeographyList?request=d',
            {}
        )
        const data = responseData(response.data) as GeographyHierarchyTreeResponse
        const actionSet = isSandbox ? slice.actions.setSandboxGeographyHierarchyTree : slice.actions.setImpactGeographyTree
        dispatch(
            actionSet({
                Region: sortByCaseSensitive(data.Region.map(geographyItemPrepareMetaData), 'Name'),
                NetworkOffice: sortByCaseSensitive(data.NetworkOffice.map(geographyItemPrepareMetaData), 'Name'),
                DistrictOffice: sortByCaseSensitive(data.DistrictOffice.map(geographyItemPrepareMetaData), 'Name'),
            })
        )
    } catch (error) {
        dispatch(slice.actions.hasError(error))
        reportError(error)
    }
})

export function fetchDeliverables() {
    return async (dispatch: Dispatch<any>) => {
        try {
            const response: any = await getResponseData('/api/Initiative/GetDeliverables?request=d', {})
            dispatch(slice.actions.setDeliverables(sortByCaseSensitive(response, 'DeliverableName')))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
            reportError(error)
        }
    }
}

export const getDeliverables = createSelector(
    (state: RootState): InitiativeState => state.initiative,
    (initiative): InitiativeDeliverable[] => initiative.deliverables
)

export function getBenefitType() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const response: any = await getResponseData('/api/BenefitType/GetBenefitTypeList?request=d', {})
            dispatch(slice.actions.setBenefitType(response))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
            reportError(error)
        }
    }
}

export function getBenefitCategory() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const response: any = await getResponseData('/api/BenefitCategory/GetBenefitCategoryList?request=d', {})
            dispatch(slice.actions.setBenefitCategories(sortByCaseSensitive(response, 'Name')))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
            reportError(error)
        }
    }
}

export function getBenefitHealth() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const response: any = await getResponseData('/api/BenefitHealth/GetBenefitHealthList?request=d', {})
            dispatch(slice.actions.setBenefitHealth(response))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
            reportError(error)
        }
    }
}

export const isInitiativeNameAvailable = async (name: string, companyId: string) => {
    if (!name) {
        return true
    }
    try {
        await getResponseData('/api/Validation/InitiativeNameAreUnique?request=d', { Name: name, Id: companyId })
        return true
    } catch (error: any) {
        reportError(error)
        return false
    }
}

export function getScenarios() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const response: any = await fetchResponseData('/api/Scenario/GetScenarioList')
            dispatch(slice.actions.setScenarios(response))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
            reportError(error)
        }
    }
}

export const fetchBusinessPositionsList = createAsyncThunk('thunk/fetchBusinessPositionsList', async (_, { dispatch }) => {
    dispatch(slice.actions.startLoading())
    try {
        const response = await getResponseData('/api/BusinessPositions/BusinessPositionsList?request=d')
        dispatch(slice.actions.setBusinessPositions(response))
        return response
    } catch (error) {
        reportError(error)
        dispatch(slice.actions.hasError(error))
    }
    return []
})

export const getBusinessPositionsList = createSelector(
    (state: RootState) => state.initiative,
    (initiative): any[] => initiative.businessPositions
)

export const getIsAddingBusinessPosition = createSelector(
    (state: RootState) => state.initiative,
    (initiative): any[] => initiative.isAddingBusinessPositions
)

export function AddBusinessPosition(name: string, onSuccess: (val: Record<string, any>, items: any[]) => void) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.idAddingBusinessPosition(true))
        try {
            // idAddingBusinessPosition
            await getResponseData(`/api/BusinessPositions/SaveBusinessPositions?request=d`, { name }).then((data) => {
                dispatch(fetchBusinessPositionsList())
                return data
            })
            const response = await getResponseData('/api/BusinessPositions/BusinessPositionsList?request=d')
            await dispatch(slice.actions.setBusinessPositions(response))
            onSuccess(find(response as BusinessPosition[], { Name: name }) || {}, response as any[])
        } catch (error) {
            reportError(error)
        } finally {
            dispatch(slice.actions.idAddingBusinessPosition(false))
        }
    }
}
