import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------
// eslint-disable-next-line
export default function Grid(theme: Theme) {
    return {
        MuiGrid: {
            styleOverrides: {
                root: {
                    // padding: '3px 1px !important',
                    margin: '5px 0',
                    maxWidth: '99%',
                    //'@media (max-width: 1400px)': {
                    padding: '1px 6px !important',
                    // },
                },
                item: {
                    paddingLeft: '5px',
                },
            },
        },
    }
}
