import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// @types
import { SettingsState, UserSettings } from '../../@types/settings'
import { fetchResponseData, getResponseData } from 'utils/axios'
import { RootState } from 'store/store'
import { createSelector } from 'reselect'
import { isEmpty } from 'lodash'

// ----------------------------------------------------------------------

const initialState: SettingsState = {
    loaded: false,
    loading: false,
    sandbox: false,
    pageTitle: '',

    userSettings: {
        themeMode: 'light',
        themeDirection: 'ltr',
        showMinutes: false,
    },
}

const slice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        switchLoadingIndicator(state, action) {
            state.loading = action.payload
        },
        switchSandbox(state, action) {
            state.sandbox = action.payload
        },
        setPageTitle(state, action) {
            state.pageTitle = action.payload
        },
        setUserSettings(state, action) {
            const settings = action.payload
            state.userSettings = {
                ...settings,
                themeMode: settings?.themeMode || initialState.userSettings.themeMode,
                themeDirection: settings?.themeDirection || initialState.userSettings.themeDirection,
            }
            state.loaded = true
        },
    },
})

// Reducer
export default slice.reducer

export const isSandBox = (state: any) => state.settings.sandbox === true && /reports|dashboard/g.test(window.location.pathname)
// Actions
export const { switchSandbox } = slice.actions

export const isSandBoxData = createSelector(
    (state: RootState) => state.settings.sandbox,
    (sandbox: boolean): boolean => sandbox === true && /reports|dashboard/g.test(window.location.pathname)
)

export const fetchUserSettings = createAsyncThunk('thunk/userSettings', async (_, { dispatch, getState }) => {
    const { loading } = (getState() as RootState).settings
    if (loading === true) {
        return
    }

    dispatch(slice.actions.switchLoadingIndicator(true))
    try {
        const data = await fetchResponseData('/umm/api/account/current')
        // @ts-expect-error: NEED better Typescript descr
        dispatch(slice.actions.setUserSettings({ ...(data?.uiSettings || {}) }))
    } catch (err: any) {
        console.log('uiSettings err', err)
    } finally {
        dispatch(slice.actions.switchLoadingIndicator(false))
    }
})

export const saveUserSettings = createAsyncThunk(
    'thunk/userSettings',
    //     async (option: Partial<EditableFieldOptionsProps> | Record<string, any>, thunkAPI) => {
    async (userSettings: UserSettings, { dispatch }) => {
        dispatch(slice.actions.setUserSettings({ ...userSettings }))
        getResponseData('/umm/api/account/ui-settings', { uiSettings: userSettings })
            .then(() => {
                // ok
            })
            .catch((err) => {
                console.error('uiSettings err', err)
            })
    }
)

export const setPageTitle = createAsyncThunk(
    'thunk/setPageTitle',
    //     async (option: Partial<EditableFieldOptionsProps> | Record<string, any>, thunkAPI) => {
    async (pageTile: string, { dispatch }) => {
        dispatch(slice.actions.setPageTitle(pageTile))
    }
)

export const getPageTitle = createSelector(
    (state: RootState) => state.settings,
    (settings): string => (isEmpty(settings.pageTitle) ? '' : settings.pageTitle)
)

export const getUserSettings = createSelector(
    (state: RootState) => state.settings,
    (settings): UserSettings => settings.userSettings || initialState.userSettings
)

export const getUserSettingsState = createSelector(
    (state: RootState) => state.settings,
    (settings): { loaded: boolean; loading: boolean } => ({ loaded: settings.loaded, loading: settings.loading })
)
